/* 검색 */
.searchWrap {
  z-index: 12;
  position: sticky;
  width: 100%;
  height: calc(100vh - 56px);
  top: 56px;
  left: 0;
  background: #ffffff;
}

.innerItem {
  margin-top: 20px;
}

.empty {
  padding: 50px 0;
}

.empty p {
  font-weight: 400;
  font-size: 18px;
  color: #9a9a9a;
  text-align: center;
}

.searchCon {
  padding: 30px 16px;
}

.searchBox + .searchBox {
  margin-top: 40px;
}

.searchBox h4 {
  font-weight: 700;
  font-size: 18px;
  color: #000000;
}

.recoSearch {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px 6px;
}

.recoSearch li div {
  display: inline-block;
  padding: 0 14px;
  height: 32px;
  line-height: 32px;
  font-weight: 400;
  font-size: 14px;
  color: #6f6f6f;
  border: 1px solid #e7e7e7;
  border-radius: 15px;
  background: #ffffff;
  cursor: pointer;
}

.popSearch {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 0;
}

.popSearch li {
  width: 50%;
  cursor: pointer;
}

.popSearch li div {
  display: flex;
  align-items: center;
  gap: 0 8px;
}

.popSearch li div > strong {
  display: block;
  min-width: 20px;
  text-align: end;
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}

.popSearch li div > span {
  font-weight: 400;
  font-size: 16px;
  color: #242424;
}

/* 검색 결과 */
.resultBox {
  background: #ffffff;
  padding: 30px 16px 20px;
}

.resultBox.slideBox {
  padding: 30px 0 20px 16px;
}

.resultBox.slideBox .resultTit,
.resultBox.slideBox .allBtn {
  padding-right: 16px;
}

.resultBox + .resultBox {
  margin-top: 8px;
}

.resultBox .resultTit {
  display: flex;
  align-items: center;
  gap: 0 8px;
}

.resultBox .resultTit h4 {
  font-weight: 700;
  font-size: 18px;
  color: #000000;
}

.resultBox .resultTit strong {
  font-weight: 700;
  font-size: 18px;
  color: #e03835;
}

/* 리뷰 영역 */
.reviewList {
  display: flex;
  flex-wrap: wrap;
  gap: 3px 3px;
}

.reviewList li {
  width: calc(33.33% - 2px);
}

.reviewInner {
  position: relative;
  width: 100%;
  cursor: pointer;
}
.reviewInner::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.reviewInner .reviewImg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #d9d9d9;
}

.reviewUser {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 6px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.4);
  font-weight: 500;
  font-size: 8px;
  color: #ffffff;
}

/* 매거진 영역 */
.magazineList {
  display: flex;
  flex-direction: column;
  gap: 12px 0;
}

/* 전체 보기 버튼 */
.allBtn {
  margin-top: 30px;
}

.allBtn button {
  width: 100%;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 5px;
  border: 0.7px solid #7e7e7e;
  border-radius: 9px;
}

.allBtn button span {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}
