.swiper-pagination {
  z-index: 3;
  position: absolute;
  bottom: 15px;
  right: 15px;
  padding: 0 7px;
  height: 18px;
  line-height: 18px;
  font-weight: 400;
  font-size: 11px;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}

.swiper-pagination .swiper-pagination-total {
  color: #d8d8d8;
}

.swiper-vertical {
  height: 12px;
  margin: 0;
  width: 100%;
}
