/* 공용 */

.wrapper {
  background-color: #000000;
}

.wrapper * {
  font-family: 'Apple SD Gothic Neo';
}

.wrapper.main {
  background: #ffffff;
}

.wrapper.main .container {
  background: #000000;
}
.container {
  position: relative;
  margin: 0 auto;
  max-width: 375px;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
}

.blur_con * {
  filter: blur(3.5px);
}

.container.resultCon {
  height: 100%;
  background: #ffffff;
}

.container.resultAllCon {
  height: 100%;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 10px 0;
  padding: 20px 0;
}

.container.resultCon .resultBox,
.container.resultAllCon .resultBox {
  padding: 0 35px;
}

.pt40 {
  padding-top: 40px;
}

.mt10 {
  margin-top: 10px;
}

.m110 {
  margin-top: 110px;
  margin-bottom: 110px;
}
/* 공용 END */

/* 메인 페이지 */
.kingTit {
  padding-top: 15px;
  font-weight: 400;
  font-size: 15px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 5px;
}

.mainBox {
  position: relative;
  height: 67.5%;
  background: #ffffff url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/king/visual_m.svg') top 50% left 50% no-repeat;
  background-size: cover;
  text-align: center;
  margin: 0 auto;
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 70px 0;
}

.mainBox::before {
  content: '';
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
}

.mainBox .logo {
  z-index: 3;
  position: relative;
  height: 47%;
}

.mainBox .logo img {
  height: 100%;
}

.mainBox .startBtn {
  z-index: 3;
  position: relative;
  margin: 0 auto;
  width: 210px;
  height: 70px;
  line-height: 62px;
  border-radius: 14px;
  background: #c31f1f;
  font-weight: 400;
  font-size: 25px;
  color: #ffffff;
  text-align: center;
  border: 4px solid #c31f1f;
  box-sizing: border-box;
}

.mainBox .startBtn:active {
  background-color: #ffffff;
  border-color: #000000;
  color: #000000;
  font-weight: 700;
}

.descBox {
  margin-top: 10px;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 26.75%;
}

.mainDesc {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
  font-family: 'GmarketSansMedium';
  font-weight: 700;
  font-size: 22px;
  line-height: 27px;
  color: rgba(255, 255, 255, 0.15);
  white-space: nowrap;
}

/* 메인 페이지 팝업*/
.startPop {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 375px;
  height: 100vh;
}

.startPop .popBox {
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 310px;
  height: 325px;
  background: #ffffff;
  border-radius: 18px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px 0;
}

.startPop .popBox .popTit {
  text-align: center;
}
.startPop .popBox .popTit span {
  display: block;
  font-weight: 600;
  font-size: 25px;
  color: #c31f1f;
}
.startPop .popBox .popTit strong {
  display: block;
  font-weight: 600;
  font-size: 30px;
  color: #000000;
}
.startPop .popBox .popTit p {
  margin-top: 20px;
  display: block;
  font-weight: 200;
  font-size: 18px;
  line-height: 20px;
  color: #000000;
}
.startPop .realStart {
  width: 240px;
  height: 50px;
  line-height: 42px;
  text-align: center;
  background: #4e4e4e;
  border: 4px solid #4e4e4e;
  box-sizing: border-box;
  border-radius: 20px;
  font-weight: 200;
  font-size: 20px;
  color: #ffffff;
}
.startPop .realStart:active {
  background-color: #ffffff;
  border-color: #000000;
  font-weight: 700;
  color: #000000;
}
.startPop .dimmed {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

/* 메인 페이지 팝업 END */

/* 메인 페이지 END */

/* 질문 페이지 */
.topCon {
  padding-top: 30px;
  width: 100%;
  height: calc(100vh - 100px);
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/king/visual_bg.png') 50% 100% no-repeat;
  background-size: cover;
}

.header {
  padding: 0 25px;
}

.Progress {
  height: 10px;
}

.ProgressCon {
  height: 10px;
  background: #ffffff;
  width: 100%;
  border: 1px solid #000000;
  box-sizing: border-box;
}

.ProgressLabel {
  position: relative;
  width: 100%;
  height: 100%;
  font-size: 0;
}

.ProgressLabel::before {
  content: '';
  position: absolute;
  top: 50%;
  right: -40px;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/king/progress_nav.svg') no-repeat;
  background-size: contain;
}

.titleBox {
  margin-top: 22px;
}

.titleBox .qState {
  font-family: 'Jeju Hallasan';
  font-weight: 400;
  font-size: 14px;
  text-align: center;
}

.result_back {
  width: 100px;
  height: 25px;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/king/back_btn.svg') no-repeat;
  background-size: contain;
}

.content {
  padding: 0 25px;
}

.content .quizBox {
  position: relative;
  margin-top: 25px;
}

.content .quizBox::before {
  z-index: 1;
  content: '';
  position: absolute;
  top: -50px;
  right: 0;
  width: 80px;
  height: 80px;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/king/quiz_deco01.svg') no-repeat;
  background-size: contain;
}

.content .quizBox::after {
  content: '';
  position: absolute;
  top: -30px;
  right: 100px;
  width: 40px;
  height: 40px;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/king/quiz_deco02.svg') no-repeat;
  background-size: contain;
}

.content .quizBox strong {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: relative;
  font-family: 'Jeju Hallasan';
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  background: #ffffff;
  text-align: center;
  height: 60px;
  color: #000000;
  white-space: pre-line;
  vertical-align: bottom;
}

.answerBox {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 25px 0;
}

.answerBox .answerBtn {
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/king/question_btn.svg') 50% 50% no-repeat;
  background-size: contain;
  font-family: 'Jeju Hallasan';
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
  white-space: pre-line;
  vertical-align: bottom;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.answerBox .answerBtn:active {
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/king/question_abtn.svg') 50% 50% no-repeat;
  color: #ffffff;
}

.questBanner {
  position: fixed;
  bottom: 0;
  max-width: 375px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
}

.questBanner .banner,
.questBanner .banner img {
  width: 100%;
}

.loadingContainer {
  max-width: 375px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  background: #ffffff;
}

.loadingContainer .loadBox {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px 0;
}

.loadImg {
  text-align: center;
}

.loadImg strong {
  display: block;
  font-weight: 600;
  font-size: 25px;
  color: #000000;
}

.loadTit {
  text-align: center;
}
.loadTit p {
  font-weight: 400;
  font-size: 20px;
  color: #a7a7a7;
}
.loadTit span {
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  color: #a7a7a7;
}
.loadTit span > strong {
  font-weight: 700;
  font-size: 20px;
  color: #c31f1f;
}

/* 질문 페이지 END */

/* 결과 페이지 */

.r_tit {
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  text-align: center;
}

/* 결과 이미지 & 타이틀 */

.ResultImg {
  position: relative;
  padding: 10px 0 15px;
  text-align: center;
}

.ResultImg::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 110px);
  border-top: 1px dashed #000000;
}

.ResultTitle {
  margin-top: 12px;
}
.ResultTitle strong {
  font-weight: 500;
  font-size: 27px;
  color: #c31f1f;
}
.ResultTitle span {
  display: block;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
}

/* 결과 설명 리스트 */

.ResultDesc {
  margin-top: 15px;
  padding: 15px 0;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid #000000;
  border-radius: 7px;
}

.ResultDesc .myDesc {
  display: flex;
  flex-direction: column;
}

.ResultDesc .myDesc li {
  position: relative;
  padding: 10px 0;
  box-sizing: border-box;
}

.ResultDesc .myDesc > li + li::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 40px);
  border-top: 1px dashed #000000;
}

.ResultDesc .myDesc li:first-child {
  padding-top: 0;
}

.ResultDesc .myDesc strong {
  display: inline-flex;
  align-items: center;
  height: 27px;
  line-height: 27px;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  background: #c31f1f;
  border-radius: 7px;
  padding: 0 13px;
}

.ResultDesc .description {
  margin: 10px 0 0;
  display: flex;
  flex-direction: column;
}

.ResultDesc .desc {
  display: block;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
  white-space: pre-line;
}

/* 결과 타입 & 케미스트리 */
.ResultType {
  margin-top: 75px;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.ResultType > div {
  position: relative;
  width: 100%;
}
.ResultType > div::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 110px);
  border-bottom: 1px dashed #000000;
}

.TypeFight {
  position: relative;
  padding-bottom: 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.TypeFight span > img {
  position: absolute;
  top: -67px;
  right: 0;
}

.TypeFight span {
  position: relative;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #000000;
}

.TypeFight span > strong {
  font-weight: 400;
  font-size: 23px;
  color: #c31f1f;
}

.TypeFace {
  padding: 20px 0;
  box-sizing: border-box;
  text-align: center;
}

.TypeFace span {
  font-weight: 400;
  font-size: 18px;
  color: #000000;
}
.TypeFace strong {
  display: block;
  font-weight: 500;
  font-size: 27px;
  line-height: 30px;
  color: #c31f1f;
}

.TypeChemi {
  padding: 20px 0 30px;
  box-sizing: border-box;
}

.TypeChemi .chemi {
  display: flex;
  text-align: center;
  justify-content: space-between;
}

.TypeChemi .chemi > li {
  flex: 1;
}

.TypeChemi .chemi strong {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 135px;
  height: 50px;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  background: #c31f1f;
  border-radius: 7px;
}

.TypeChemi .chemi .description {
  margin-top: 5px;
}

.TypeChemi .chemi .desc {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #000000;
}

/* 결과 마무리*/
.ResultEnd {
  padding: 20px 0 30px;
  text-align: center;
}

.ResultEnd .rComm {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px 10px;
  border: 1px solid #000000;
  border-radius: 7px;
  box-sizing: border-box;
}
.ResultEnd .rComm p {
  font-size: 20px;
}

.ResultEnd .rComm span {
  margin-top: 7px;
  display: block;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #000000;
}

.ResultEnd .resultBtn {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px 0;
}

.ResultEnd .resultBtn .allResult {
  width: 100%;
  height: 37px;
  line-height: 37px;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  background: #121212;
  border-radius: 7px;
}
.ResultEnd .resultBtn .reStart {
  width: 100%;
  height: 37px;
  line-height: 37px;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
  background: #c31f1f;
  border-radius: 7px;
}

.ResultEnd .shareBox {
  margin-top: 20px;
}

.ResultEnd .shareBox h4 {
  font-weight: 400;
  font-size: 15px;
  color: #000000;
}

.ResultEnd .shareBox .shareList {
  margin: 15px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 10px;
}

.topBanner,
.botBanner {
  width: 100%;
  cursor: pointer;
}
.topBanner img,
.botBanner img {
  width: 100%;
}

/* 결과 배너 팝업 */

.popBox {
  z-index: 3;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.popBox > div > img {
  border-radius: 9px;
}

.popBox .popTimer {
  position: absolute;
  top: 15px;
  right: 15px;
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.popBox .popTimer .timerTxt {
  font-weight: 400;
  font-size: 15px;
  color: #ffffff;
}
.popBox .popTimer .timerTxt strong {
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
}

.popBox .popClose:disabled {
  cursor: default;
}

.dimmedB {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 100vh;
  max-width: 375px;
  width: 100%;
  background: rgba(255, 255, 255, 0.3);
}
/* 결과 배너 팝업 END */

.collapseBtn {
  margin-top: 20px;
  width: 100%;
  height: 37px;
  line-height: 37px;
  background: #121212;
  border-radius: 7px;
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
  text-align: center;
}
