/* font */

/* 뷰티북 폰트 */
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 100;
  src: url('/src/components/common/font/NotoSans-Thin.woff') format('woff'), url('/src/components/common/font/NotoSans-Thin.woff2') format('woff2');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 200;
  src: url('/src/components/common/font/NotoSans-DemiLight.woff') format('woff'), url('/src/components/common/font/NotoSans-DemiLight.woff2') format('woff2');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 300;
  src: url('/src/components/common/font/NotoSans-Light.woff') format('woff'), url('/src/components/common/font/NotoSans-Light.woff') format('woff2');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  src: url('/src/components/common/font/NotoSans-Regular.woff') format('woff'), url('/src/components/common/font/NotoSans-Regular.woff2') format('woff2');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 500;
  src: url('/src/components/common/font/NotoSans-Medium.woff') format('woff'), url('/src/components/common/font/NotoSans-Medium.woff2') format('woff2');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 700;
  src: url('/src/components/common/font/NotoSans-Bold.woff') format('woff'), url('/src/components/common/font/NotoSans-Bold.woff2') format('woff2');
}
@font-face {
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 900;
  src: url('/src/components/common/font/NotoSans-Black.woff') format('woff'), url('/src/components/common/font/NotoSans-Black.woff2') format('woff2');
}

/* 심리 테스트 폰트 */
@font-face {
  font-family: 'Apple SD Gothic Neo';
  font-style: normal;
  font-weight: 100;
  src: url('/src/components/common/font//AppleSDGothicNeoT.ttf') format('truetype');
}

@font-face {
  font-family: 'Apple SD Gothic Neo';
  font-style: normal;
  font-weight: 200;
  src: url('/src/components/common/font//AppleSDGothicNeoL.ttf') format('truetype');
}

@font-face {
  font-family: 'Apple SD Gothic Neo';
  font-style: normal;
  font-weight: 300;
  src: url('/src/components/common/font//AppleSDGothicNeoM.ttf') format('truetype');
}

@font-face {
  font-family: 'Apple SD Gothic Neo';
  font-style: normal;
  font-weight: 400;
  src: url('/src/components/common/font//AppleSDGothicNeoSB.ttf') format('truetype');
}

@font-face {
  font-family: 'Apple SD Gothic Neo';
  font-style: normal;
  font-weight: 500;
  src: url('/src/components/common/font//AppleSDGothicNeoB.ttf') format('truetype');
}

@font-face {
  font-family: 'Apple SD Gothic Neo';
  font-style: normal;
  font-weight: 600;
  src: url('/src/components/common/font//AppleSDGothicNeoEB.ttf') format('truetype');
}

@font-face {
  font-family: 'Apple SD Gothic Neo';
  font-style: normal;
  font-weight: 700;
  src: url('/src/components/common/font//AppleSDGothicNeoH.ttf') format('truetype');
}

@import url(//fonts.googleapis.com/earlyaccess/jejuhallasan.css);

@font-face {
  font-family: 'GmarketSansMedium';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansMedium.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NeoDgm';
  src: url('/src/components/common/font/neodgm.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'NeoDgm';
  src: url('/src/components/common/font/neodgm.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body::-webkit-scrollbar {
  display: none;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

.mapPin {
  position: absolute;
  left: 0;
  bottom: 10px;
  transform: translateX(-50%);
  background: rgba(220, 38, 34, 0.65);
  border: 1px solid #dc2622;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  padding: 10px;
  border-radius: 25px;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  white-space: nowrap;
}

.mapPin > a {
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  white-space: nowrap;
}
