/* 상단 메인 검색 헤더 */
.HomeTopBox {
  z-index: 11;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 56px;
  padding: 0 16px;
  box-sizing: border-box;
  background: #ffffff;
}

.Homeinner {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.Homeinner .HomeLogo {
  display: block;
  min-width: 80px;
  width: 80px;
}

.Homeinner .HomeLogo img {
  width: 100%;
}

.Homeinner .searchBox {
  flex: 1;
}

.Homeinner .searchBox .searchLink {
  cursor: default;
}

.Homeinner input {
  z-index: 9;
  position: relative;
  width: 100%;
  height: 36px;
  line-height: 36px;
  padding: 0 18px 0 36px;
  border: none;
  border-radius: 10px;
  background: #f0f1f5 url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/search_ic.svg') top 50% left 14px no-repeat;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 12px;
  color: #1b1b1b;
}

.Homeinner form input::placeholder {
  font-weight: 400;
  font-size: 12px;
  color: #999999;
}

.Homeinner form input:focus {
  outline: none;
}

/* 하단 고정 Footer 메뉴 */
.barBox {
  z-index: 10;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.barBox.browser {
  width: 460px;
}

.barBox::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  background: #ffffff;
}

.barBox .menuList {
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  padding: 0 10px;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px rgba(74, 74, 74, 0.07);
}

.barBox .menuList li {
  flex: 1;
  text-align: center;
}

.barBox .menuList li span {
  position: relative;
  display: block;
  margin-top: 4px;
  font-weight: 400;
  font-size: 10px;
  color: #1c1c1c;
}

.barBox .homeBtn {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  width: 55px;
  height: 55px;
  background: #e03835;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
}

.barBox .menuList.leftCircle {
  border-radius: 60px 0 0 0;
  padding: 0 0 0 35px;
}
.barBox .menuList.rightCircle {
  border-radius: 0 60px 0 0;
  padding: 0 35px 0 0;
}

.barBox .menuList .select span {
  font-weight: 500;
}

.barBox .menuList .select span::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -7px;
  transform: translateX(-50%);
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: #e03835;
}

.push {
  position: relative;
}

.push .pushCnt {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #e03835;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 400;
  font-size: 10px;
}

button.cancel {
  width: 30px;
  height: 30px;
}
