.slideCon {
  position: relative;
}

.slideImg {
  width: 100%;
}

.slideBG {
  background: #d8d8d8;
  width: 100%;
  height: 280px;
  cursor: pointer;
}

.slideSubBG {
  background: #d8d8d8;
  width: 100%;
  height: 110px;
}

.slideBG .shopInfo {
  padding: 12px;
  box-sizing: border-box;
}

.slideBG .shopInfo .infoDesc {
  margin-top: 10px;
}

.slideBG .shopInfo .infoDesc > strong,
.squareBox .shopInfo .infoDesc > strong {
  display: block;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.slideBG .shopInfo .infoDesc > span,
.squareBox .shopInfo .infoDesc > span {
  margin-top: 5px;
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #797979;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.slideBG .shopInfo .infoDesc > h4,
.squareBox .shopInfo .infoDesc > h4 {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 16.8px;
  color: #1a1a1a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.empty {
  padding: 50px 0;
}

.empty p {
  font-weight: 400;
  font-size: 18px;
  color: #9a9a9a;
  text-align: center;
}

.innerItem {
  margin-top: 20px;
}

.innerItem.shadowSwiper > div {
  padding-bottom: 10px;
}

.itemBox {
  border: 1px solid #e1e1e1;
  border-radius: 16px;
  box-sizing: border-box;
}

.itemBox .shopImg {
  position: relative;
  width: 100%;
  height: 200px;
  background: #c4c4c4;
  border-radius: 16px 16px 0 0;
}

.itemBox .shopImg .wishIc,
.squareItem .wishIc {
  z-index: 3;
  position: absolute;
  top: 12px;
  right: 12px;
}

.itemBox .shopInfo {
  padding: 12px;
  box-sizing: border-box;
}

.itemBox .shopInfo .infoUtil {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.squareBox .shopInfo .infoUtil {
  margin-top: 7px;
}

.itemBox .shopInfo .infoUtil .infoTag {
  display: flex;
  align-items: center;
  gap: 0 5px;
}

.itemBox .shopInfo .infoUtil .infoTag li {
  height: 20px;
  line-height: 20px;
  font-weight: 400;
  font-size: 10px;
  color: #e03835;
  padding: 0 9px;
  border: 1px solid #e03835;
  box-sizing: border-box;
  border-radius: 15px;
}

.itemBox .shopInfo .infoRate,
.mapListItem .mapInner .mapShopDesc .infoRate,
.squareBox .shopInfo .infoRate {
  margin-top: 7px;
  display: flex;
  align-items: center;
  gap: 0 3px;
}

.itemBox .shopInfo .infoRate strong,
.mapListItem .mapInner .mapShopDesc .infoRate strong,
.squareBox .shopInfo .infoRate strong {
  font-weight: 500;
  font-size: 14px;
  color: #1a1a1a;
}

.itemBox .shopInfo .infoRate span,
.mapListItem .mapInner .mapShopDesc .infoRate span,
.squareBox .shopInfo .infoRate span {
  font-weight: 400;
  font-size: 14px;
  color: #b8b8b8;
}

.itemBox .shopInfo .infoDesc > strong,
.squareBox .shopInfo .infoDesc > strong {
  font-weight: 700;
  font-size: 16px;
  color: #242424;
}

.itemBox .shopInfo .infoDesc > span,
.squareBox .shopInfo .infoDesc > span {
  margin-top: 5px;
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #797979;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* MD's Pick , 기획전 BEST */

.squareBox {
  position: relative;
}

.squareInner {
  position: relative;
  width: 100%;
}

.squareInner::after {
  content: '';
  display: block;
  padding-top: 100%;
}

.squareItem {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #dbdbdb;
  border-radius: 16px;
}

.squareItem::before {
  z-index: 2;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.2);
}

.squareItem .pickTit {
  z-index: 3;
  position: absolute;
  left: 12px;
  bottom: 12px;
}

.squareItem .pickTit strong {
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}
.squareItem .pickTit span {
  margin-top: 5px;
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
}

.squareItem .bestTit {
  z-index: 3;
  position: absolute;
  top: 12px;
  left: 12px;
}

.squareItem .bestTit strong {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}

/* 뷰티북 추천 샵 & 신규 입점 샵 & 검색란 샵 */
.squareBox .shopInfo {
  margin-top: 12px;
}

/* 리얼 리뷰 아이템 */
.reviewInner {
  width: 235px;
  background: #ffffff;
  border-radius: 16px;
  border: 1px solid #f5f5f5;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.09);
  box-sizing: border-box;
}

.reviewInner .reviewUser {
  padding: 12px;
  display: flex;
  align-items: center;
  gap: 0 8px;
}

.reviewInner .reviewUser .userImg {
  width: 28px;
  height: 28px;
  background: #d9d9d9;
  border-radius: 50%;
}

.reviewInner .reviewImg {
  position: relative;
  height: 200px;
  background: #c4c4c4;
}

.reviewInner .reviewImg::before {
  content: '';
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
}

.reviewInner .reviewImg p {
  z-index: 3;
  position: absolute;
  left: 12px;
  bottom: 12px;
  width: calc(100% - 24px);
  height: 22px;
  box-sizing: border-box;
  display: block;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.reviewInner .reviewShop {
  padding: 12px;
}

.reviewInner .reviewShop strong {
  display: block;
  font-weight: 700;
  font-size: 16px;
  color: #1a1a1a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.reviewInner .reviewShop .reviewUtil {
  margin-top: 13px;
  display: flex;
  align-items: center;
  gap: 0 3px;
}
.reviewInner .reviewShop .reviewUtil span {
  font-weight: 500;
  font-size: 14px;
  color: #1a1a1a;
}
.reviewInner .reviewShop .reviewUtil p {
  font-weight: 400;
  font-size: 14px;
  color: #b8b8b8;
}
.reviewInner .reviewShop .reviewCate {
  display: block;
  width: 100%;
  margin-top: 10px;
  font-weight: 400;
  font-size: 11px;
  color: #797979;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 이벤트 아이템 */
.eventList {
  display: flex;
  gap: 0 12px;
  padding: 20px 0;
  border-bottom: 1px solid #f0f0f0;
}

.eventList .eventImg {
  min-width: 120px;
  height: 120px;
  background: #c4c4c4;
  border-radius: 16px;
}

.eventInfo {
  width: calc(100% - 132px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.eventList .eventDesc strong {
  display: block;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #1a1a1a;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.eventList .eventDesc .eventTag {
  margin-top: 5px;
  display: flex;
  align-items: center;
  gap: 0 3px;
}

.eventList .eventDesc .eventTag li {
  display: block;
  font-weight: 400;
  font-size: 12px;
  color: #888888;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.eventList .eventDesc .eventShop {
  margin-top: 5px;
  display: flex;
  align-items: center;
}

.eventList .eventDesc .eventShop li {
  display: block;
  font-weight: 400;
  font-size: 12px;
  color: #bbbbbb;
  padding: 0 3px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.eventList .eventDesc .eventShop li:first-child {
  padding-left: 0;
}
.eventList .eventDesc .eventShop li:last-child {
  padding-right: 0;
}

.eventList .eventDesc .eventPrice {
  margin-top: 8px;
  display: block;
  font-weight: 700;
  font-size: 16px;
  color: #dc2622;
}

.eventList .eventDate {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0 5px;
}

.eventList .eventDate span {
  display: block;
  min-width: 35px;
  padding: 0 3px;
  height: 15px;
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #ffffff;
  background: #888888;
  border-radius: 4px;
  text-align: center;
}

.eventList .eventDate p {
  display: block;
  font-weight: 400;
  font-size: 12px;
  color: #888888;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 샵 리스트 공통 스타일 */
.shopInner {
  padding: 16px 16px 20px 16px;
  background: #ffffff;
}

.searchShopInner {
  padding: 72px 16px 20px 16px;
  background: #ffffff;
}

.filterList {
  display: flex;
  align-items: center;
  gap: 0 12px;
}

.filterList li {
  padding: 0 15px;
  height: 32px;
  background: #f5f5f5;
  border-radius: 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color: #acacac;
  cursor: pointer;
}

.filterList li.select {
  background: #e03835;
  font-weight: 700;
  color: #ffffff;
}

.ShopList {
  display: flex;
  flex-direction: column;
  gap: 20px 0;
}

.botButton {
  z-index: 3;
  padding: 0 25px;
  position: fixed;
  bottom: 75px;
  width: 60px;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 10px;
  gap: 5px 0;
  color: #ffffff;
  background: #e03835;
  border-radius: 50%;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
}

.botButton.wButton {
  background: #ffffff;
  color: #e03835;
  filter: drop-shadow(0px 0px 12px rgba(0, 0, 0, 0.2));
}

/* 맵 필터 */
.filterContent {
  z-index: 11;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #ffffff;
}

.filterContent .filterBox {
  margin-top: 30px;
  padding: 0 16px;
}

.filterContent .filterBox h4 {
  font-weight: 400;
  font-size: 16px;
  color: #181818;
}

.filterContent .filterBox h4 + form {
  margin-top: 16px;
}

.filterContent .filterBox .MapFilterList {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 14px;
  align-items: center;
}

.filterContent .filterBox .MapFilterList .filterItem {
  width: calc(33% - 9px);
}

.filterContent .filterSubmit {
  position: absolute;
  bottom: 12px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 32px);
  height: 46px;
  background: #e03835;
  border-radius: 9px;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
}

.filterItem input[type='checkbox'] {
  display: none;
}

.filterItem input[type='checkbox'] + label {
  display: block;
  width: 100%;
  height: 35px;
  line-height: 35px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  border-radius: 9px;
  font-weight: 400;
  font-size: 14px;
  color: #797979;
  text-align: center;
  cursor: pointer;
}

.filterItem input[type='checkbox']:checked + label {
  border-color: #dc2622;
  background-color: rgba(220, 38, 34, 0.1);
  color: #dc2622;
}

/* 최근 본 샵 */
.recentBox {
  display: flex;
}

.recentBox .recentImg {
  min-width: 150px;
  height: 150px;
  background: #c4c4c4;
  border-radius: 16px 0 0 16px;
}

.recentBox .recentDesc {
  width: 100%;
  background: #ffffff;
  border-radius: 0 16px 16px 0;
  padding: 12px 14px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.recentBox .recentDesc .recentTit strong {
  font-weight: 700;
  font-size: 16px;
  color: #1a1a1a;
}

.recentBox .recentDesc .recentTit span {
  margin-top: 5px;
  display: block;
  font-weight: 400;
  font-size: 12px;
  color: #797979;
}

.recentBox .recentDesc .recentUtil {
  display: flex;
  align-items: center;
  gap: 0 5px;
}

.recentBox .recentDesc .recentUtil strong {
  font-weight: 500;
  font-size: 14px;
  color: #313131;
}

/* 매거진 아이템 영역 */
.magazineList {
  display: flex;
  flex-direction: column;
  gap: 12px 0;
}

.magazineItemBox {
  display: flex;
  align-items: flex-start;
  gap: 0 12px;
}

.magazineItemBox .magazineImg {
  width: 150px;
  min-width: 150px;
  height: 100px;
  background: #c4c4c4;
  border-radius: 12px;
}

.magazineItemBox .magazineDesc {
  width: calc(100% - 162px);
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.magazineItemBox .magazineDesc .magazineInfo strong {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 500;
  font-size: 14px;
  color: #1a1a1a;
}

.magazineItemBox .magazineDesc .magazineInfo span {
  margin-top: 5px;
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #797979;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.magazineItemBox .magazineDesc .magazineInfo .searchTag {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 3px 3px;
  flex-wrap: wrap;
}

.magazineItemBox .magazineDesc .magazineInfo .searchTag li {
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: #888888;
}

.magazineItemBox .magazineDesc .magazineUtil {
  display: flex;
  align-items: center;
  gap: 0 12px;
}

.magazineItemBox .magazineDesc .magazineUtil img {
  width: 19px;
  height: 19px;
}

.magazineItemBox .magazineDesc .magazineUtil .magazineIc {
  display: flex;
  align-items: center;
  gap: 0 5px;
}

.magazineItemBox .magazineDesc .magazineUtil span {
  font-weight: 400;
  font-size: 11px;
  color: #b8b8b8;
}

/* 샵 상세 컴포넌트 스타일링 */

.detailContent {
  position: relative;
}

.eventDetail img {
  width: 100%;
}

/* 샵 정보 */
.detailShopBox {
  background: #ffffff;
}

.detailTop {
  z-index: 11;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  height: 56px;
  background: #ffffff;
}

.detailTop strong {
  display: block;
  width: calc(100% - 60px);
  font-weight: 700;
  font-size: 20px;
  color: #242424;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
}

.detailShopBox .detailImgContent {
  width: 100%;
  height: 250px;
  background: #f5f5f5;
}

.detailShopBox .detailImgContent .detailImg {
  width: 100%;
  height: 250px;
  background: #f5f5f5;
}

.detailShopBox .detailImgContent button {
  position: absolute;
  z-index: 10;
}

.detailShopBox .detailImgContent button.detailBack {
  top: 17px;
  left: 15px;
}

.detailShopBox .detailImgContent button.detailWish {
  top: 17px;
  right: 15px;
}

.detailShopBox .detailDesc {
  padding: 30px 16px;
}

.detailShopBox .detailDesc strong {
  font-weight: 700;
  font-size: 20px;
  color: #242424;
}

.detailShopBox .detailDesc p {
  margin-top: 8px;
  display: block;
  font-weight: 400;
  font-size: 14px;
  color: #3e3e3e;
}

.detailShopBox .detailDesc span {
  margin-top: 8px;
  display: block;
  font-weight: 400;
  font-size: 13px;
  color: #a3a3a3;
}

.detailShopBox .detailDesc .shopRate {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 0 3px;
}

.detailShopBox .detailDesc .shopRate strong {
  font-weight: 500;
  font-size: 16px;
  color: #313131;
}

/* 해쉬태그 영역 */
.hashTagBox {
  margin-top: 8px;
  padding: 10px 16px;
  background: #ffffff;
}

.hashTagBox .hashTagList {
  display: flex;
  align-items: center;
  gap: 0 6px;
}

.hashTagBox .hashTagList .swiper-slide {
  width: auto;
}

.hashTagBox .hashTagList li a {
  display: inline-block;
  height: 20px;
  line-height: 20px;
  padding: 0 5px;
  font-weight: 400;
  font-size: 12px;
  color: #838383;
  border: 0.5px solid #d2d2d2;
  border-radius: 9px;
  background: #f8f8f8;
  white-space: nowrap;
}

/* 탭 영역 */
.detailTab {
  position: relative;
  margin-top: 8px;
}

/* 공통 */
.secContent {
  background: #ffffff;
  padding: 30px 16px;
}

.secContent + .secContent {
  margin-top: 8px;
}

.secContent .secTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.secContent .secTitle h4 {
  font-weight: 700;
  font-size: 20px;
  color: #000000;
}

.secContent .secTitle div {
  display: flex;
  align-items: center;
  gap: 0 5px;
}

.secContent .secTitle div span {
  font-weight: 400;
  font-size: 12px;
  color: #666666;
}

.innerContent {
  margin-top: 20px;
}

.innerContent h4 {
  margin-top: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #0e0e0e;
}

.innerContent a {
  word-wrap: break-word;
}

.flexContent {
  display: flex;
  align-items: flex-start;
  gap: 0 14px;
}

.contentBtn {
  margin-top: 20px;
  width: 100%;
  height: 45px;
  line-height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 5px;
  border: 0.7px solid #7e7e7e;
  border-radius: 9px;
}

.contentBtn span {
  font-weight: 400;
  font-size: 14px;
  color: #666666;
}

/* 샵 소개 */
.introDesc {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #0e0e0e;
}

/* 샵 공지 */
.noticeProfile {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #f3f3f3;
}

.noticeTalk {
  position: relative;
  flex: 1;
  display: block;
  padding: 12px;
  background: #d9d9d9;
  border-radius: 9px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #555555;
}

.noticeTalk::before {
  content: '';
  position: absolute;
  top: 12px;
  left: -2.5px;
  width: 6px;
  height: 6px;
  background: #d9d9d9;
  transform: rotate(45deg);
}

/* 편의 시설 */
.facilitiesList {
  display: flex;
  flex-wrap: wrap;
  gap: 25px 35px;
}

.facilitiesList li {
  width: calc(25% - 26.3px);
  text-align: center;
}

.facilitiesList li span {
  margin-top: 5px;
  display: block;
  font-weight: 400;
  font-size: 12px;
  color: #313131;
  word-break: break-word;
}

/* 가격 */
.priceFilter {
  padding: 15px 0;
}

.priceFilter .filerList {
  display: flex;
  align-items: center;
  gap: 0 12px;
}

.priceFilter .filerList .filterItem {
  height: 32px;
  padding: 0 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  background: #f5f5f5;
  border-radius: 15px;
  color: #acacac;
  cursor: pointer;
}

.priceFilter .filerList .filterItem.select {
  background-color: #dc2622;
  color: #ffffff;
}

.priceHead {
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid #f0f0f0;
  padding: 20px 0;
  box-sizing: border-box;
}

.priceHead h4 {
  font-weight: 700;
  font-size: 16px;
  color: #0e0e0e;
}

.priceHead img {
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
  transition: all 0.3s;
}

.priceHead.open img {
  transform: translateY(-50%) rotate(180deg);
}

.priceList .priceItem {
  border-bottom: 1px solid #f0f0f0;
}
.priceFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
}

.priceFlex strong {
  display: block;
  width: calc(100% - 30%);
  font-weight: 400;
  font-size: 14px;
  color: #0e0e0e;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}

.priceFlex strong label,
input {
  cursor: pointer;
}

/* ----------------------수정해야함------------------ */
.priceFlex strong input[type='checkbox'] {
  padding: 0;
  border: transparent;
  width: 18px;
  height: 18px;
  appearance: none;

  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/term_off.svg') no-repeat;
  background-size: 18px 18px;
}

.priceFlex strong input[type='checkbox']:checked {
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/term_on.svg');
}

.priceFlex strong label {
  vertical-align: text-top;
  padding-left: 10px;
}
/* ----------------------수정해야함------------------ */

.priceFlex .priceInfo {
  display: flex;
  align-items: center;
  gap: 0 12px;
}

.priceFlex .priceInfo .default {
  font-weight: 400;
  font-size: 14px;
  color: #b8b8b8;
  text-decoration: line-through;
}

.priceFlex .priceInfo .discount {
  font-weight: 400;
  font-size: 14px;
  color: #0e0e0e;
}

/* 리뷰 */
.reviewList {
  border-bottom: 1px solid #e3e3e3;
}

/* 사진 */

.photoList {
  display: flex;
  flex-wrap: wrap;
  gap: 3px 3px;
}

.photoList li {
  width: calc(33.33% - 2px);
}

.photoInner {
  position: relative;
  width: 100%;
}
.photoInner::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.photoInner .photos {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #d9d9d9;
}

/* 샵 상세 정보 */
.infoList {
  display: flex;
  flex-direction: column;
  gap: 18px 0;
}

.infoList strong {
  font-weight: 600;
  font-size: 14px;
  color: #242424;
}

.infoList span {
  margin-top: 8px;
  display: block;
  font-weight: 400;
  font-size: 14px;
  color: #1a1a1a;
}

.infoList span > a {
  cursor: pointer;
}

/* 하단 고정 바*/
.fixedBar {
  z-index: 10;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
  padding: 13px 16px;
  box-sizing: border-box;
  border-top: 1px solid #f7f7f7;
  background: #ffffff;
  display: flex;
  align-items: center;
  gap: 16px;
}

.fixedBar.browser {
  position: fixed;
  width: 460px;
}

.fixedBar .utilBtn {
  display: flex;
  align-items: center;
  gap: 0 16px;
}

.fixedBar .utilBtn > button {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f8f8;
  border-radius: 9px;
}

.fixedBar .fixedBtn {
  flex: 1;
  height: 45px;
  line-height: 45px;
  background: #e03835;
  border-radius: 9px;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
}

.fixedBar .fixedBtn:disabled {
  background: #b8b8b8;
}

/* --------------변경해야함----------------- */
.fixedBar .serviceBox {
  line-height: 22.5px;
}

.fixedBar .serviceBox h4 {
  font-weight: 400;
  font-size: 14px;
  color: #888888;
}

.fixedBar .serviceBox h4 + h4 {
  font-weight: 500;
  font-size: 16px;
  color: #e03835;
}

/* 탭 공통 영역 */
.tabContent {
  padding: 10px 16px;
  background: #ffffff;
}

.noticeTabContent {
  background: #ffffff;
}

.eventContent {
  position: relative;
}

/* 매거진 메인 */
.magazineSec {
  padding: 20px 16px;
}

.magazineSec.p40 {
  padding: 40px 16px;
}

.magazineSec.pt40 {
  padding-top: 40px;
}

.magazineSec.pb40 {
  padding-bottom: 40px;
}

.magazineSec h4 {
  font-weight: 700;
  font-size: 20px;
  color: #242424;
}

.mdTypeWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 13px 13px;
}

.mdTypeWrap li {
  width: calc(50% - 13px);
}

/* 매거진 상세 */
.mzInfo {
  background: #ffffff;
}

.mzInfo .mzImg {
  position: relative;
  width: 100%;
  background: #c4c4c4;
}

.mzInfo .mzImg::before {
  content: '';
  display: block;
  padding-top: 66.66%;
}

.mzInfo .mzTitle {
  padding: 20px 16px;
  border-bottom: 1px solid #f0f0f0;
}

.mzInfo .mzTitle .mzUtil {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.mzInfo .mzTitle .mzUtil .mzCate {
  display: inline-block;
  height: 26px;
  line-height: 26px;
  padding: 0 6px;
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  background: #e03835;
  border-radius: 5px;
}

.mzInfo .mzTitle .mzDesc strong {
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #1a1a1a;
}

.mzContent {
  background: #ffffff;
}

.mzContent .mzInner {
  padding: 20px 16px;
}

.mzContent .mzInner img {
  max-width: 100%;
}

.mzContent strong {
  font-weight: 700;
}

.mzContent .mzTagBox {
  padding: 20px 16px 25px;
  border-bottom: 1px solid #ededed;
}

.mzContent .mzTagBox .mzTagList {
  display: flex;
  flex-wrap: wrap;
  gap: 6px 6px;
}

.mzContent .mzTagBox .mzTagList li span {
  display: inline-block;
  height: 21px;
  line-height: 21px;
  padding: 0 6px;
  background: #f8f8f8;
  border: 0.5px solid #d2d2d2;
  border-radius: 9px;
  font-weight: 400;
  font-size: 12px;
  color: #838383;
}

.mzBoardUtil {
  padding: 20px 16px;
  display: flex;
  align-items: center;
  gap: 0 16px;
}

.mzBoardUtil .wishCnt,
.mzBoardUtil .commentCnt {
  display: flex;
  align-items: center;
  gap: 0 6px;
  cursor: pointer;
}

.mzBoardUtil .wishCnt img,
.mzBoardUtil .commentCnt img {
  width: 15px;
}

.mzBoardUtil .wishCnt span,
.mzBoardUtil .commentCnt span {
  font-weight: 400;
  font-size: 14px;
  line-height: 11px;
  color: #696969;
}

.mzConnect {
  margin-top: 8px;
  padding: 30px 16px;
  background: #ffffff;
}

.mzConnect h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

/* 이벤트 홈 */

.eventInner {
  padding: 0 16px;
}

.eventName {
  z-index: 3;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 30px;
  line-height: 30px;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  background: rgba(224, 56, 53, 0.8);
  border-radius: 0 0 16px 16px;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 샵 상세 맵 */
.storeName {
  z-index: 3;
  position: absolute;
  display: inline-block;
  padding: 0 10px;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  background: #e03835;
  border-radius: 30px;
  color: #ffffff;
}

/* 샵 찾기 맵 아이템*/
.mapListItem {
  background: #ffffff;
  border-radius: 16px;
  border: 1px solid #f5f5f5;
  box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.09);
  padding: 20px 10px;
}

.mapListItem .mapInner {
  display: flex;
  align-items: center;
  gap: 0 12px;
}

.mapListItem .mapInner .mapShopImg {
  width: 70px;
  height: 70px;
  border-radius: 8px;
  background: #d9d9d9;
}

.mapListItem .mapInner .mapShopDesc > strong {
  font-weight: 700;
  font-size: 16px;
  color: #1a1a1a;
  cursor: pointer;
}

.mapListItem .mapInner .mapShopDesc > span {
  display: block;
  margin-top: 10px;
  width: 100%;
  font-weight: 400;
  font-size: 11px;
  color: #797979;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.mapListItem .mapUtil {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 0 6px;
}

.mapListItem .mapUtil .callBtn {
  width: 36px;
  height: 36px;
  background: #f8f8f8;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mapListItem .mapUtil .detailBtn {
  flex: 1;
  height: 36px;
  line-height: 36px;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
  background: #e03835;
  border-radius: 9px;
}

/* --------------변경해야함----------------- */
.reservationContent {
  padding: 30px 16px;
}

.reservationContent .reservationBox {
  padding-bottom: 20px;
  border-bottom: 1px solid #f0f0f0;
}
.reservationContent .reservationBox form {
  padding-bottom: 20px;
}

.reservationContent .reservationBox.noPad {
  padding: 0;
}

.reservationContent .reservationBox:last-child {
  border-bottom: 0;
}

.reservationContent .reservationBox .dateSelectWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.reservationContent .reservationBox .dateSelectWrap select {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  text-transform: uppercase;
  color: #1c1c1c;
  width: 139px;
  height: 38px;
  outline: none;
  padding: 0 12px;
  border-radius: 30px;
  border: 1px solid #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.dateSelectWrap select {
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/dropdown_ic.svg') right 13px top 50% no-repeat;
}

.reservationContent .reservationBox p {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #e03835;
  padding-bottom: 20px;
  word-break: break-word;
}

.reservationContent .reservationBox h4 {
  font-weight: 500;
  font-size: 18px;
  color: #181818;
}

.reservationContent .reservationBox .reservationTit {
  margin: 20px 0;
  position: relative;
  cursor: pointer;
}

.reservationContent .reservationBox .reservationTit img {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  transition: all 0.3s;
}

.reservationContent .reservationBox .reservationTit.isOpen img {
  transform: rotate(180deg);
}

.reservationContent .reservationBox h4 span {
  font-weight: 500;
  font-size: 18px;
  color: #e03835;
}

.reservationContent .reservationBox h4 + form {
  margin-top: 20px;
}

.reservationContent .reservationBox .reservationList {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 14px;
  align-items: center;
}

.reservationContent .reservationBox .reservationList .dateTime {
  width: calc(25% - 12px);
}

.dateTime input[type='checkbox'] {
  display: none;
}

.dateTime input[type='checkbox'] + label {
  display: block;
  width: 100%;
  height: 46px;
  line-height: 46px;
  background: #f9f9f9;
  border: 1px solid #f9f9f9;
  border-radius: 9px;
  font-weight: 400;
  font-size: 18px;
  color: #797979;
  text-align: center;
  cursor: pointer;
}

.dateTime input[type='checkbox']:disabled + label {
  border-color: #b8b8b8;
  background-color: #b8b8b8;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
  cursor: default;
}

.dateTime input[type='checkbox']:checked + label {
  border-color: #e03835;
  background-color: #e03835;
  color: #ffffff;
}

.reservationContent .reservationBox .dateItem {
  padding: 0 10px;
  text-align: center;
}

.dateItem input[type='radio'] {
  display: none;
}

.dateItem input[type='radio'] + p {
  font-weight: 400;
  font-size: 16px;
  color: #1c1c1c;
  padding: 0;
}

.dateItem input[type='radio'] + p.sunday {
  color: #e03835;
}

.dateItem input[type='radio'] + p.saturday {
  color: #6f7df9;
}

.dateItem input[type='radio'] + p + label + small {
  display: block;
  font-weight: 400;
  font-size: 12px;
  color: #797979;
  padding: 0;
  cursor: pointer;
}

.dateItem input[type='radio'] + p + label {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 28px;
  background: #ffffff;
  font-weight: 400;
  font-size: 16px;
  color: #1c1c1c;
  text-align: center;
  margin: 10px 0;
  cursor: pointer;
}

.dateItem input[type='radio']:disabled + p + label {
  color: #e7e7e7;
  cursor: default;
}

.dateItem input[type='radio'].today + p + label {
  background-color: #f9f9f9;
  border: 1px solid #f9f9f9;
  border-radius: 50%;
}

.dateItem input[type='radio']:checked + p + label {
  background-color: #e03835;
  border: 1px solid #e03835;
  border-radius: 50%;
  color: #ffffff;
  font-weight: 700;
}

.categoryContent {
  padding: 15px 16px;
  background: #ffffff;
}

.categoryBox .inputInner .inputBox {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.categoryBox .inputInner input,
.categoryBox .inputInner select {
  padding: 0 12px;
  height: 42px;
  border: 0.7px solid #bbbbbb;
  border-radius: 9px;
  box-sizing: border-box;
}

.categoryBox .inputInner select {
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/dropdown_ic.svg') top 50% right 18px no-repeat;
}

.categoryBox .inputInner input.inputFull,
.categoryBox .inputInner select.inputFull {
  width: 100%;
  cursor: pointer;
}

.categoryBox .inputInner .radioList {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0 35px;
}

.categoryBox .inputInner .radioList .radioBox {
  display: inline-flex;
  align-items: center;
  gap: 0 10px;
}

.categoryBox .inputInner .radioList .radioBox input[type='radio'] {
  padding: 0;
  border: transparent;
  width: 18px;
  height: 18px;
  appearance: none;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/term_off.svg') no-repeat;
  background-size: 18px 18px;
}

.categoryBox .inputInner .radioList .radioBox input[type='radio']:checked {
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/term_on.svg');
}

.categoryBox .inputInner .radioList .radioBox label {
  font-weight: 400;
  font-size: 12px;
  color: #4d4d4d;
}

/* 예약 결제 화면*/
.paymentInfo {
  background: #ffffff;
  padding: 16px 16px 36px 16px;
}

.paymentInfo .paymentBox {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.1);
  border-radius: 23px;
}

.paymentInfo .paymentBox .phChk .phChkImg {
  margin: 0 auto;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #e03835 url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/payment/pay_chk.svg') 50% 50% no-repeat;
}

.paymentInfo .paymentBox .phChk p {
  margin-top: 6px;
  display: block;
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  text-align: center;
}

.paymentInfo .paymentBox .phChk p > strong {
  font-weight: 700;
  font-size: 14px;
  color: #e03835;
}

.paymentInfo .paymentBox .phInfo {
  margin-top: 30px;
  padding: 0 10px;
}

.paymentInfo .paymentBox .phInfo strong {
  font-weight: 500;
  font-size: 20px;
  color: #1a1a1a;
}

.paymentInfo .paymentBox .phInfo .phCate {
  margin-top: 15px;
  padding: 15px 0;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-top: 1px solid #c9c9c9;
}

.paymentInfo .paymentBox .phInfo .phCate .phSelectCate span {
  font-weight: 400;
  font-size: 14px;
  color: #1a1a1a;
}

.paymentInfo .paymentBox .phInfo .phCate .phSelectCate strong {
  margin-top: 5px;
  display: Block;
  font-weight: 500;
  font-size: 16px;
  color: #1a1a1a;
}

.paymentInfo .paymentBox .phInfo .phCate .phPrice {
  display: flex;
  align-items: center;
  gap: 0 6px;
}

.paymentInfo .paymentBox .phInfo .phCate .phPrice span {
  font-weight: 400;
  font-size: 12px;
  color: #b8b8b8;
  text-decoration: line-through;
}

.paymentInfo .paymentBox .phInfo .phCate .phPrice strong {
  font-weight: 700;
  font-size: 14px;
  color: #e03835;
}

.paymentInfo .paymentBox .phDate {
  margin-top: 15px;
  padding-bottom: 20px;
  border-bottom: 1px solid #c9c9c9;
}

.paymentInfo .paymentBox .phDate ul {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paymentInfo .paymentBox .phDate ul li {
  display: flex;
  flex-direction: column;
  gap: 6px 0;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.paymentInfo .paymentBox .phDate ul li + li {
  border-left: 1px solid #c9c9c9;
}

.paymentInfo .paymentBox .phDate ul li span {
  font-weight: 400;
  font-size: 12px;
  color: #a4a4a4;
}

.paymentInfo .paymentBox .phDate ul li strong {
  font-weight: 700;
  font-size: 18px;
  color: #e03835;
}

.paymentInfo .paymentBox .phSpare {
  margin-top: 20px;
}

.paymentInfo .paymentBox .phSpare span {
  font-weight: 400;
  font-size: 12px;
  color: #a4a4a4;
}

.paymentInfo .paymentBox .phSpare strong {
  margin-top: 10px;
  display: block;
  padding-left: 20px;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/payment/spare_chk.svg') left 0 top 50% no-repeat;
  font-weight: 400;
  font-size: 16px;
  color: #1a1a1a;
}

.paymentInfo .paymentUserInfo {
  margin-top: 30px;
}

.paymentMethod h4 {
  font-weight: 400;
  font-size: 16px;
  color: #1a1a1a;
  margin-bottom: 20px;
}

.paymentTypeList {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 14px;
  align-items: center;
}

.paymentTypeList .paymentType {
  width: calc(33% - 9px);
}

.paymentType input[type='radio'] {
  display: none;
}

.paymentType input[type='radio'] + label {
  display: block;
  width: 100%;
  height: 46px;
  line-height: 46px;
  background: #ffffff;
  border: 1px solid #b8b8b8;
  border-radius: 9px;
  font-weight: 400;
  font-size: 14px;
  color: #1c1c1c;
  text-align: center;
  cursor: pointer;
}

.paymentType input[type='radio'] + .ptKakao {
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/payment/kakao_pay.svg') 50% 50% no-repeat;
}
.paymentType input[type='radio'] + .ptNaver {
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/payment/naver_pay.svg') 50% 50% no-repeat;
}
.paymentType input[type='radio'] + .ptPayco {
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/payment/payco.svg') 50% 50% no-repeat;
}
.paymentType input[type='radio'] + .ptSamsung {
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/payment/samsung_pay.svg') 50% 50% no-repeat;
}
.paymentType input[type='radio'] + .ptApple {
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/payment/apple_pay.svg') 50% 50% no-repeat;
}

.paymentType input[type='radio']:checked + label {
  background-color: #e03835;
  border-color: #e03835;
  color: #ffffff;
  font-weight: 500;
}

.paymentType input[type='radio']:checked + .ptKakao {
  background-image: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/payment/kakao_pay_w.svg');
}
.paymentType input[type='radio']:checked + .ptNaver {
  background-image: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/payment/naver_pay_w.svg');
}
.paymentType input[type='radio']:checked + .ptPayco {
  background-image: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/payment/payco_w.svg');
}
.paymentType input[type='radio']:checked + .ptSamsung {
  background-image: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/payment/samsung_pay_w.svg');
}
.paymentType input[type='radio']:checked + .ptApple {
  background-image: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/payment/apple_pay_w.svg');
}

.puInput + .puInput {
  margin-top: 20px;
}
.puInput strong {
  margin-bottom: 10px;
  display: block;
  font-weight: 400;
  font-size: 16px;
  color: #1a1a1a;
}

.puInput input,
.puInput select {
  width: 100%;
  height: 42px;
  padding: 0 12px;
  border: 1px solid #b8b8b8;
  box-sizing: border-box;
  border-radius: 9px;
  font-weight: 400;
  line-height: 42px;
  outline: none;
  color: #1a1a1a;
  font-size: 16px;
  cursor: pointer;
}

.puInput input:disabled,
.puInput select:disabled {
  color: #adadad;
  cursor: default;
}

.pointDesc {
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #b8b8b8;
}

.termDesc {
  margin-top: 10px;
}

.termDesc li {
  margin-left: 10px;
  font-weight: 400;
  font-size: 11px;
  line-height: 18px;
  color: #b8b8b8;
  list-style-type: '- ';
  word-break: keep-all;
}

.puInput select {
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/dropdown_ic.svg') right 18px top 50% no-repeat;
}

.puInput textarea {
  width: 100%;
  height: 160px;
  padding: 0 12px;
  border: 1px solid #b8b8b8;
  box-sizing: border-box;
  border-radius: 9px;
  font-weight: 400;
  font-size: 16px;
  line-height: 42px;
  color: #1a1a1a;
  outline: none;
  resize: none;
}

.paymentInfo .messageNotice {
  margin-top: 30px;
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #797979;
  text-align: center;
}

.paymentMethod .messageNotice {
  margin-top: 30px;
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #797979;
  text-align: center;
}

.paymentBenefit,
.paymentPrice,
.paymentMethod,
.paymentTerm {
  margin-top: 10px;
  padding: 30px 16px;
  background: #ffffff;
}

.paymentBenefit .pbPoint {
  display: flex;
  align-items: center;
  gap: 0 8px;
}

.paymentBenefit .pbPoint button {
  min-width: 90px;
  height: 42px;
  line-height: 42px;
  font-weight: 400;
  font-size: 16px;
  color: #1a1a1a;
  border: 1px solid #b8b8b8;
  border-radius: 9px;
}

.paymentBenefit .pbPoint button:disabled {
  color: #adadad;
}

.paymentBenefit .pbPoint button:active {
  background-color: #e03835;
  border-color: #e03835;
  color: #ffffff;
}

.paymentPrice > strong {
  font-weight: 500;
  font-size: 16px;
  color: #1a1a1a;
}

.paymentPrice ul {
  padding: 20px 0;
  border-bottom: 1px solid #f0f0f0;
}

.paymentPrice ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paymentPrice ul li + li {
  margin-top: 15px;
}

.paymentPrice ul li span {
  font-weight: 400;
  font-size: 16px;
  color: #1a1a1a;
}

.paymentPrice .resultPrice {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.paymentPrice .resultPrice strong {
  font-weight: 500;
  font-size: 16px;
  color: #1a1a1a;
}

.paymentPrice .resultPrice span {
  font-weight: 700;
  font-size: 18px;
  color: #e03835;
}

.paymentTerm > strong {
  font-weight: 400;
  font-size: 16px;
  color: #1a1a1a;
}

.paymentTerm .termInput {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 0 7px;
}

.paymentTerm .termInput input[type='checkbox'] {
  padding: 0;
  border: transparent;
  width: 18px;
  height: 18px;
  appearance: none;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/term_off.svg') no-repeat;
  background-size: 18px 18px;
}

.paymentTerm .termInput input[type='checkbox']:checked {
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/term_on.svg');
}

.paymentTerm .termInput label {
  font-weight: 400;
  font-size: 14px;
  color: #242424;
  cursor: pointer;
}

.paymentTerm .termInput label p {
  display: inline-block;
  color: #e03835;
}

.paymentButton {
  padding: 15px 16px;
  background: #ffffff;
}

.payBtn {
  margin: 0 auto;
  width: 100%;
  height: 46px;
  line-height: 46px;
  border-radius: 9px;
  background: #e03835;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
}

.payBtn:disabled {
  background-color: #b8b8b8;
}

.tabFixed {
  z-index: 11;
  position: sticky;
  top: 56px;
  left: 0;
  margin: 0;
  width: 100%;
}

.productInfo {
  display: flex;
  align-items: center;
  gap: 0 5px;
  padding-bottom: 18px;
}

.productInfo img {
  width: 20.34px;
  height: 20.34px;
}

.productInfo span {
  font-weight: 400;
  font-size: 12px;
  color: #e03835;
}

.filterInner {
  padding: 20px 16px;
  background: #ffffff;
}

.listWrap {
  position: relative;
}

.searchFilterInner {
  padding: 20px 16px;
  background: #ffffff;
  position: fixed;
  width: 100%;
  z-index: 5;
}

.searchFilterInner.browser {
  width: 460px;
}

/* 기획전 */
.bannerTest {
  width: 100%;
  height: 160px;
  background: #6d6d6d;
}

/* 이미지 모달 */
.imgModal {
  z-index: 99;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
}

.imgModal.browser {
  z-index: 99;
  position: fixed;
  width: 460px;
  height: 100%;
}

.imgModal .imgModalBox {
  z-index: 12;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px 0;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
}

.imgModal .imgModalBox img {
  width: 100%;
}

.imgModal .imgModalBox .modalImgView {
  width: 100%;
  height: 100%;
}

.imgModal .imgModalBox .modalSliderView {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgModal .imgModalBox .modalSliderView > div {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.imgModal .imgModalBox .modalSliderView > div img {
  width: 100%;
  height: 100%;
}

.imgModal .imgModalBox .modalImgView .modalImgList > div {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.imgModal .imgModalBox .modalImgView .modalImgList img {
  max-width: 100%;
  max-height: 100%;
}

.imgModal .imgModalBox .modalGallery {
  padding: 30px 0;
  width: 100%;
  overflow: hidden;
}

.imgModal .imgModalBox .modalGallery > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.imgModal .imgModalBox .modalGalleryList {
  width: 80px;
  height: 80px;
}

.imgModal .imgModalBox .modalGalleryList img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
}

.marketShare {
  position: fixed;
  width: 100%;
  bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px 0;
  padding: 0 8px;
}

.marketShare.browser {
  width: 460px;
}

.marketShare button {
  width: 45px;
}

.marketShare button img {
  width: 100%;
}

/* 랜딩 하단 댓글 */
.fixedBar .eventComment {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0 16px;
}

.fixedBar .eventComment > button {
  min-width: 45px;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f8f8f8;
  border-radius: 9px;
}

.fixedInput {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0 8px;
}

.fixedInput input {
  width: calc(100% - 88px);
  height: 45px;
  line-height: 45px;
  border-radius: 10px;
  outline: none;
  border: 1px solid #dddddd;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 400;
  color: #000000;
}

.fixedInput input::placeholder {
  font-size: 14px;
  font-weight: 400;
  color: #777777;
}

.fixedInput > button {
  width: 80px;
  height: 45px;
  border-radius: 10px;
  line-height: 45px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  background: #e03835;
}

.fixedInput > button:disabled {
  background-color: #b8b8b8;
}

.modalClose {
  cursor: pointer;
  z-index: 99;
  position: absolute;
  top: 15px;
  left: 15px;
}

@media (max-width: 480px) {
  .categoryBox .inputInner .radioList {
    margin: 20px 0 0 0;
    width: 100%;
  }

  .categoryBox .inputInner .radioList .radioBox {
    width: calc(30% - 9px);
    display: inline-flex;
    align-items: center;
    gap: 0 10px;
  }
}

@media (max-width: 360px) {
  .facilitiesList {
    gap: 25px 10px;
  }

  .facilitiesList li {
    width: calc(25% - 7.5px);
  }

  .reservationContent .reservationBox .dateItem {
    padding: 0;
  }

  .dateItem input[type='radio'] + p + label {
    width: 25px;
    height: 25px;
    line-height: 24px;
    font-size: 14px;
  }
}

@media (max-width: 340px) {
  .filterItem input[type='checkbox'] + label {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 8px;
  }

  .dateTime input[type='checkbox'] + label,
  .dateTime input[type='checkbox']:disabled + label {
    font-size: 12px;
  }

  .reservationContent .reservationBox h4 {
    font-size: 14px;
  }

  .reservationContent .reservationBox h4 > span {
    font-size: 12px;
  }
}
