/* 공통 박스 */
.blind {
  font-size: 0;
}

.container {
  width: 100%;
  min-height: 100vh;
  background: #ffffff;
}

.renewal_container {
    width: 100%;
    min-height: 100vh;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.scrollOff {
  overflow: hidden;
}

.container * {
  font-family: 'Noto Sans KR';
}

.container.fixed {
  padding-bottom: 60px;
}

.container.detail {
  padding-bottom: 70px;
}

.container.fixedBtn {
  padding-bottom: 130px;
}

.container.gray {
  background-color: #fafafa;
}

.header {
  z-index: 10;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 58px;
  background: #ffffff;
  box-shadow: 0 6px 4px -4px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .back {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
}

.header .notice,
.header .share {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
}

.notice .pushCnt {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background: #e03835;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-weight: 400;
  font-size: 10px;
}

.header h4 {
  font-weight: 700;
  font-size: 20px;
  color: #242424;
}

/* 상단 고정 레이아웃 - 컨텐츠 박스*/
.beautyWrap {
  z-index: 9;
  position: relative;
}

.beautyWrap.full {
  height: calc(100vh - 60px);
}

.beautyWrap.fixed {
  padding-bottom: 55px;
}

.beautyWrap.fixedDetail {
  padding-bottom: 70px;
}

.beautyWrap.fixedBtn {
  padding-bottom: 125px;
}

.beautyWrap.detail {
  margin-bottom: 70px;
}

.bgGray {
  background: #f0f0f0;
}

.bgGrayA {
  background: #fafafa;
}

/* 마진 여백 */
.mt10 {
  margin-top: 10px;
}

/* 검색 상단 & 하단 네비 메뉴 여백 */
.innerWrap {
  padding: 30px 16px;
  display: flex;
  flex-direction: column;
  gap: 20px 0;
}

/* Dim */
.dimmed {
  z-index: 11;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.modalPop {
  z-index: 100;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
}

.modalPop.browser {
  width: 460px;
}

.modalPop .cancel {
  padding: 0 16px;
}

.modalPop .cancel .cancelBtn {
  margin-top: 22px;
  width: 100%;
  height: 46px;
  line-height: 46px;
  background: #e03835;
  border-radius: 9px;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
}

.modalPop .modalBtn {
  margin-top: 22px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  gap: 0 8px;
}

.modalPop .modalBtn button {
  flex: 1;
  height: 46px;
  line-height: 46px;
  border-radius: 9px;
  font-size: 16px;
}

.modalPop .modalBtn .cancelBtn {
  background: #e7e7e7;
  color: #888888;
  font-weight: 500;
}

.modalPop .modalBtn .submitBtn {
  background: #e03835;
  font-weight: 700;
  color: #ffffff;
}

.modalMsg {
  z-index: 101;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
}

.modalMsg.browser {
  width: 460px;
}

.modalMsg .msgBox {
  z-index: 12;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 72.58%;
  height: 150px;
  background: #ffffff;
  border-radius: 9px;
  box-shadow: 0px 0px 31px 1px rgba(0, 0, 0, 0.15);
}

.modalMsg .msgBox .msg {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modalMsg .msgBox .msg span {
  font-weight: 400;
  font-size: 16px;
  color: #1c1c1c;
  line-height: 20px;
  text-align: center;
}

.modalMsg .msgBox .msgBtn {
  width: 100%;
  height: 50px;
  line-height: 50px;
  font-weight: 400;
  font-size: 16px;
  color: #e03835;
  text-align: center;
  border-top: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 0 0 9px 9px;
}

.modalMsg .msgBox .msgTwoBtn {
  width: 50%;
  height: 50px;
  line-height: 50px;
  color: #e03835;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  border-top: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 0 0 9px 9px;
}

.modalMsg .msgBox .msgTwoBtn.cancel {
  color: #888888;
}

/* 탭 메뉴 */
.tabList {
  position: relative;
  display: flex;
  align-items: center;
  background: #ffffff;
}

.tabList.fixed {
  z-index: 3;
  position: sticky;
  top: 58px;
}

.tabList.swiperTab .tabItem {
  width: 16.66%;
}

.tabList::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #f0f0f0;
}

.tabList .tabItem {
  z-index: 2;
  cursor: pointer;
  flex: 1;
  height: 55px;
  line-height: 55px;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #6d6d6d;
  border-bottom: 2px solid #f0f0f0;
  box-sizing: border-box;
}

.tabList .tabItem.flexTab {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0 3px;
}

.tabList .tabItem.flexTab span {
  font-weight: 400;
  font-size: 16px;
  color: #6d6d6d;
}

.tabList .tabItem.flexTab p {
  font-weight: 400;
  font-size: 8px;
  color: #b1b1b1;
}

.tabList .slideItem {
  cursor: pointer;
  height: 55px;
  line-height: 55px;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #6d6d6d;
  border-bottom: 2px solid #f0f0f0;
  box-sizing: border-box;
}

.tabList .tabItem.select,
.tabList .slideItem.select,
.tabList .tabItem.select span,
.tabList .tabItem.select p {
  border-color: #e03835;
  color: #e03835;
  font-weight: 700;
}

/* 하단 고정 버튼 */
.botSubmit {
  z-index: 3;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 12px 16px;
  background: #ffffff;
  box-sizing: border-box;
}

.botSubmit.browser {
  width: 460px;
}

.botSubmit .submit {
  display: block;
  width: 100%;
  height: 46px;
  line-height: 46px;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
  background: #e03835;
  border-radius: 9px;
}

.botSubmit .submit:disabled {
  background: #b8b8b8;
}

.empty {
  padding: 130px 0;
}

.empty p {
  font-weight: 400;
  font-size: 18px;
  color: #9a9a9a;
  text-align: center;
}

.footer {
  background: #f0f0f0;
}

.footer .footerInner {
  padding: 30px 16px;
}

.footer .footerInner h4 {
  font-weight: 500;
  font-size: 12px;
  color: #797979;
}

.footer .footerInner .footerAddr {
  margin-top: 15px;
  font-weight: 400;
  font-size: 11px;
  line-height: 26px;
  color: #797979;
}

.footerAddr a {
  font-weight: 400;
  font-size: 11px;
  line-height: 26px;
  color: #797979;
}

.footer .footerInner .footerMenu {
  margin-top: 25px;
  display: flex;
  align-items: center;
}

.footer .footerInner .footerMenu li {
  position: relative;
  padding: 0 5px;
}

.footer .footerInner .footerMenu li + li:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 12px;
  background: #797979;
}

.footer .footerInner .footerMenu li:first-child {
  padding-left: 0;
}
.footer .footerInner .footerMenu li:last-child {
  padding-right: 0;
}

.footer .footerInner .footerMenu li a {
  display: block;
  font-weight: 500;
  font-size: 12px;
  color: #797979;
}

/* 마진 */

.mt20 {
  margin-top: 20px;
}

/* 카카오맵 */
.mapUtil {
  z-index: 3;
  position: absolute;
  bottom: 160px;
  left: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px 0;
}

.mapUtil.listOn {
  bottom: 280px;
}

.mapUtil button {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0px 0px 8px -3px rgba(0, 0, 0, 0.35);
  background: #ffffff;
  border: 0.5px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mapUtil button.active {
  border-color: #e03835;
  background-color: #ffe9e9;
}

.mapUtil button.mapClose {
  background-color: #646464;
  border-color: #646464;
}

.mapList {
  z-index: 3;
  position: absolute;
  bottom: 90px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}

.mapList .listBtn {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 5px;
  padding: 0 20px;
  background: #ffffff;
  height: 50px;
  border-radius: 30px;
  box-shadow: 0px 0px 8px -3px rgba(0, 0, 0, 0.35);
}

.mapList .listBtn span {
  font-weight: 700;
  font-size: 16px;
  color: #e03835;
}

.mapSearch {
  z-index: 3;
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px 0;
  width: calc(100% - 20px);
}

.mapSearch .msBox {
  display: flex;
  justify-content: center;
  width: 100%;
}

.mapSearch input {
  width: calc(100% - 32px);
  height: 36px;
  line-height: 36px;
  background: #ffffff url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/locSearch.svg') left 14px top 50% no-repeat;
  border-radius: 10px;
  border: 1px solid #e7e7e7;
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  padding: 0 36px;
  box-sizing: border-box;
  outline: none;
}

.mapSearch input::placeholder {
  font-weight: 400;
  font-size: 12px;
}

.mapSearch .locSearch {
  height: 30px;
  display: flex;
  align-items: Center;
  justify-content: center;
  gap: 0 5px;
  padding: 0 10px;
  font-weight: 400;
  font-size: 12px;
  color: #797979;
  background: #ffffff;
  border-radius: 30px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
}

/* Dimmed */
.mapDimmed {
  z-index: 99;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.mapDimmed .dimmedBox {
  z-index: 3;
  position: absolute;
  bottom: 130px;
  left: 16px;
  display: flex;
  flex-direction: column;
  gap: 20px 0;
}

.mapDimmed .dimmedBox .close {
  display: flex;
  align-items: center;
  gap: 0 8px;
}

.mapDimmed .dimmedBox .close span {
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
}

.mapDimmed .dimmedBox .dimUtil {
  display: flex;
  flex-direction: column;
  gap: 13px 0;
}

.mapDimmed .dimmedBox .dimUtil .cateBal {
  display: flex;
  align-items: center;
  gap: 0 22px;
}

.mapDimmed .dimmedBox .dimUtil .locBal {
  display: flex;
  flex-direction: column;
  gap: 17px 0;
}

.mapDimmed .dimmedBox .dimUtil .dimBal {
  position: relative;
  background: #ffffff;
  box-shadow: 4px 4px 10px 1px rgba(0, 0, 0, 0.09);
  border-radius: 12px;
  padding: 10px 15px;
}

.mapDimmed .dimmedBox .dimUtil .cateBal .dimBal::before {
  content: '';
  position: absolute;
  left: -6px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
  width: 12px;
  height: 12px;
  background: #ffffff;
}

.mapDimmed .dimmedBox .dimUtil .locBal .dimBal::before {
  content: '';
  position: absolute;
  left: 19px;
  bottom: -6px;
  transform: rotate(45deg);
  width: 12px;
  height: 12px;
  background: #ffffff;
}

.mapDimmed .dimmedBox .dimUtil .dimBal span {
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  color: #e03835;
}

.mapDimmed .dimmedBox .dimUtil .dimBtn {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0px 0px 8px -3px rgba(0, 0, 0, 0.35);
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.resultWrap {
  position: relative;
}

.termModal {
  z-index: 12;
  position: fixed;
  top: 0;
  width: 100%;
}

.termModal.browser {
  width: 460px;
}

.termModal .termCon {
  height: calc(100vh - 58px);
  background: #ffffff;
  padding: 30px 16px;
  overflow-y: scroll;
}

.termModal .termCon::-webkit-scrollbar {
  display: none;
}

.termModal .termCon h3 {
  color: #242424;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.termModal .termCon p {
  color: #242424;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

/* 반응형 작업 */
@media (max-width: 420px) {
  .tabList.swiperTab .tabItem {
    width: 22.5%;
    flex: none;
  }
}

@media (max-width: 340px) {
  .tabList .slideItem {
    font-size: 12px;
  }
}

@media (max-width: 320px) {
  .tabList.swiperTab .tabItem {
    width: 37.5%;
    flex: none;
  }
}
