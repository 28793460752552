.boardWrap {
  margin-top: 58px;
}

.boardWrap .empty {
  padding-top: 100px;
  text-align: center;
}

.boardWrap .empty p {
  font-weight: 400;
  font-size: 18px;
  color: #9a9a9a;
}

.noticeItem {
  position: relative;
  border-top: 1px solid #f0f0f0;
}

.noticeHead {
  padding: 20px 16px 16px;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/dropdown_ic.svg') top 50% right 16px no-repeat;
  cursor: pointer;
}

.noticeHead span {
  display: flex;
  align-items: center;
  gap: 0 5px;
  font-weight: 400;
  font-size: 16px;
  color: #242424;
}

.noticeHead p {
  margin-top: 5px;
  font-weight: 400;
  font-size: 12px;
  color: #9a9a9a;
}

.noticeContent {
  background: #f2f2f2;
  padding: 16px 18px;
}

.noticeContent p {
  font-weight: 400;
  font-size: 14px;
  color: #797979;
}

.noticeContent img {
  width: 100%;
  max-width: 100%;
  cursor: pointer;
}

.noticeContent a {
  cursor: pointer;
}

/* 자주 찾는 문의*/
.qnaTab {
  padding: 0 16px;
}

.tabList {
  display: flex;
  flex-wrap: wrap;
  gap: 1px 1px;
}

.tabList .tabItem {
  position: relative;
  width: calc(25% - 1px);
  height: 50px;
  line-height: 50px;
  font-weight: 400;
  font-size: 14px;
  color: #565656;
  text-align: center;
  border: 1px solid #dbdbdb;
  box-sizing: border-box;
  background: #ffffff;
}

.tabList .tabItem.select {
  background-color: #e03835;
  border-color: #e03835;
  font-weight: 500;
  color: #ffffff;
}

.tabList .tabItem.select::before {
  z-index: 3;
  content: '';
  position: absolute;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%) rotate(-45deg);
  width: 6px;
  height: 6px;
  background: #e03835;
}

.qnaSearch {
  padding: 20px 16px 0;
}

.qnaSearch .inputBox {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0 5px;
}

.qnaSearch .inputBox input {
  width: calc(100% - 55px);
  height: 36px;
  line-height: 36px;
  background: #f0f1f5 url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/search_ic.svg') 16px 50% no-repeat;
  border-radius: 10px;
  font-weight: 400;
  font-size: 12px;
  color: #565656;
  padding: 0 16px 0 38px;
  border: none;
  outline: none;
}

.qnaSearch .inputBox input::placeholder {
  font-weight: 400;
  font-size: 12px;
  color: #999999;
}

.qnaSearch .inputBox .searchBtn {
  width: 50px;
  height: 36px;
  line-height: 36px;
  background: #e03835;
  border: 0.8px solid #e03835;
  border-radius: 9px;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
}

.qnaList {
  margin: 10px 0 60px 0;
}

.qnaList .qnaItem {
  position: relative;
  border-bottom: 1px solid #f2f2f2;
}

.qnaList .qnaItem .question {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0 12px;
  padding: 20px 40px 20px 16px;
  background: #ffffff url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/dropdown_ic.svg') right 16px top 27px no-repeat;
}

.qnaList .qnaItem .question > .questionIc {
  font-weight: 500;
  font-size: 13px;
  color: #565656;
  line-height: 20px;
}

.qnaList .qnaItem .question > span {
  font-weight: 400;
  font-size: 12px;
  color: #565656;
  line-height: 20px;
}

.qnaList .qnaItem .question > strong {
  color: #e03835;
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
}

.qnaList .qnaItem .answer {
  padding: 20px 16px;
  background: #f2f2f2;
  display: flex;
  align-items: flex-start;
  gap: 0 10px;
}

.show {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #797979;
  word-wrap: break-word;
}

.show a {
  display: block;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #797979;
  word-wrap: break-word;
}

.hide {
  display: none;
}

.qnaList .qnaItem .answer > .answerIc {
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e03835;
  border-radius: 50%;
  font-weight: 500;
  font-size: 13px;
  color: #f9f9f9;
}

.qnaList .qnaItem .answer > .answerContent {
  width: calc(100% - 34px);
}

.qnaList .qnaItem .answer > p {
  font-weight: 400;
  font-size: 14px;
  color: #797979;
}

.qnaList .qnaItem .answer > .answerContent a {
  cursor: pointer;
}

/* 푸쉬 메세지 */
.pushMsgList .pushMsgItem {
  padding: 20px 16px;
  box-sizing: border-box;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
}

.pushMsgList .pushMsgItem .pushTit {
  display: flex;
  align-items: center;
  gap: 0 5px;
}

.pushMsgList .pushMsgItem .pushTit strong {
  font-weight: 400;
  font-size: 16px;
  color: #242424;
}

.pushMsgList .pushMsgItem .pushComment {
  margin-top: 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #888888;
}
.pushMsgList .pushMsgItem .pushDate {
  margin-top: 12px;
  display: block;
  font-weight: 400;
  font-size: 12px;
  color: #9a9a9a;
  text-align: right;
}
