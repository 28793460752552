.wrapper {
  background-color: #dbedff;
}

.wrapper * {
  font-family: 'Apple SD Gothic Neo';
}

.container {
  position: relative;
  margin: 0 auto;
  max-width: 500px;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding-bottom: 10px;
  box-sizing: border-box;
  background: url('../img/mentality_bg.svg') no-repeat;
  background-size: cover;
}

.container.resultCon {
  height: 100%;
}

.pt10 {
  padding-top: 10px;
}

.pt80 {
  padding-top: 80px;
}

.mt80 {
  margin: 80px 0 200px;
}

.botBox.mt25 {
  margin-top: 25px;
}

.content {
  padding: 0 16px;
  box-sizing: border-box;
}

.mBr {
  display: none;
}
/* 공용 END */

/* 메인 상단 박스 */
.testBox {
  position: relative;
  display: flex;
  justify-content: center;
}

.testBox > img {
  width: 85%;
}

.testFix {
  position: absolute;
  bottom: -63px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 7px 0;
}

.testFix span {
  font-weight: 400;
  font-size: 14px;
  color: #6f6f6f;
  text-align: center;
}

.usage {
  max-width: 210px;
}

.testFix p {
  font-weight: 400;
  font-size: 12px;
  color: #6f6f6f;
}
/* 메인 상단 박스 END */

/* 메인 하단 고정 박스 */
.botBox {
  position: fixed;
  bottom: 25px;
}

.botBox .banner {
  cursor: pointer;
  padding: 0 16px;
  max-width: 500px;
  width: 100%;
  background: transparent;
  display: flex;
  align-items: center;
  gap: 0 7px;
  box-sizing: border-box;
}

.botBox .banner img {
  width: 100%;
}

.botBox .banner.b_ani {
  animation: invert 4s ease-in infinite;
}

@keyframes invert {
  0% {
    filter: invert(0);
  }
  50% {
    filter: invert(1);
  }
  100% {
    filter: invert(0);
  }
}

.botBox.result {
  margin-top: 20px;
  box-sizing: border-box;
  position: relative;
  bottom: auto;
}

.botBox .banner img {
  width: 100%;
}

.botBox .b_img {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.shareBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px 0;
}

.shareBox .flexRow {
  display: flex;
  align-items: center;
  gap: 0 15px;
}

.shareBox p {
  font-weight: 600;
  font-size: 14px;
  color: #6f6f6f;
}

/* 메인 하단 고정 박스 END */

/* 테스트(퀴즈) 페이지 */
.topCon {
  display: flex;
  flex-direction: column;
  gap: 15px 0;
}

.header {
  padding: 0 16px;
  box-sizing: border-box;
}

.titleBox {
  position: relative;
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.titleBox h4 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 600;
  font-size: 18px;
  color: #000000;
}

.backBtn {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 17px;
  height: 17px;
}

.qState {
  font-weight: 500;
  font-size: 14px;
  color: #a5a5a5;
}

.quizBox {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 126px;
}

.quizBox strong {
  font-weight: 800;
  font-size: 30px;
  line-height: 42px;
  color: #141414;
  white-space: pre-line;
  vertical-align: bottom;
}

.answerBox {
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px 0;
}

.answerBtn {
  border-radius: 9px;
  background: #ffffff;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #141414;
  white-space: pre-line;
  vertical-align: bottom;
}

.answerBtn:active {
  transition: 0;
  color: #ffffff;
  background-color: #7d93ff;
}

/* 로딩 */
.loadingContainer {
  margin: 0 auto;
  position: relative;
  width: 375px;
  height: 100vh;
  background: url('../img/mentality_bg.png');
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadBox {
  z-index: 3;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px 0;
}
.loadBox strong {
  font-weight: 800;
  font-size: 30px;
  line-height: 42px;
  color: #ffffff;
}

.loadPop {
  width: 285px;
  height: 73px;
  border: 2px solid #213854;
  border-radius: 3px;
}

.loadT {
  width: 100%;
  height: 18px;
  padding: 0 5px;
  background: #ffa3de;
  border-bottom: 2px solid #213854;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.loadB {
  width: 100%;
  height: 51px;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2px 0;
}

.loadB span {
  font-weight: 600;
  font-size: 12px;
  color: #213854;
  letter-spacing: 0.1rem;
}

.loadB .loadBar {
  position: relative;
  width: 245px;
  height: 18px;
  border: 2px solid #213854;
  box-sizing: border-box;
  background: #f7f7f7;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.loadB .loadBar::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 4px;
  width: calc(100% - 8px);
  transform: translateY(-50%);
  border: 5px solid #7598f3;
  box-sizing: border-box;
  animation: load 3s linear;
}

@keyframes load {
  0% {
    width: 0;
  }

  100% {
    width: calc(100% - 8px);
  }
}

.dimmed {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: calc(100% + 200px);
  background: rgba(0, 0, 0, 0.4);
}

/* 로딩 END */

/* 테스트(퀴즈) 페이지 END */

/* 결과 페이지 */

.topBox {
  position: relative;
  padding: 0 18px;
}

.topBox::before {
  z-index: 1;
  content: '나의 데이터';
  position: absolute;
  top: -50px;
  left: 18px;
  width: 190px;
  height: 70px;
  background: url('../img/before.svg') no-repeat;
  background-size: cover;
  font-weight: 600;
  font-size: 18px;
  line-height: 60px;
  color: #a1a1a1;
  text-align: center;
}

.topBox .resultBox {
  z-index: 2;
  position: relative;
  background: #ffffff;
  border: 3px solid #414141;
  border-radius: 9px;
  box-sizing: border-box;
}

.topBox .resultBox > div {
  padding: 30px 20px;
  box-sizing: border-box;
}

.topBox .resultBox > div.mbtiMatch {
  text-align: center;
}

.topBox .resultBox > div + div {
  border-top: 3px solid #414141;
}

.myMbti {
  text-align: center;
}

.mbtiDesc .description {
  display: flex;
  flex-direction: column;
  gap: 15px 0;
}

.mbtiDesc .description .desc {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #141414;
  word-break: break-word;
}

.myTit {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 7px 0;
}

.myTit span {
  font-weight: 500;
  font-size: 22px;
  color: #1a1a1a;
}

.myTit strong {
  font-weight: 800;
  font-size: 32px;
  color: #4960d2;
}

.matchBox {
  margin-bottom: 55px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.matchBox li {
  flex: 1;
}

.matchList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px 0;
}

.goodM .matchList strong {
  color: #7d93ff;
}
.badM .matchList strong {
  color: #ff7bac;
}

.matchList img {
  width: 110px;
}

.matchList strong {
  font-weight: 800;
  font-size: 18px;
}

.matchList span {
  font-weight: 600;
  font-size: 14px;
  color: #1a1a1a;
}

.restartBtn {
  margin-top: 40px;
  width: 100%;
  height: 50px;
  line-height: 50px;
  background: #7d93ff;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
}

/* 결과 페이지 팝업 */
.dimmedB {
  z-index: 2;
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: calc(100% + 400px);
  background: rgba(0, 0, 0, 0.8);
}

.popContainer {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: transparent;
}

.popBox {
  z-index: 3;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border-radius: 9px;
  width: 343px;
  height: 550px;
}

.popImg {
  width: 100%;
  height: 100%;
}

.popImg > img {
  border-radius: 9px;
}

.popBox .popClose:disabled {
  cursor: default;
}

.popTimer {
  position: absolute;
  top: 16px;
  right: 16px;
  display: flex;
  align-items: flex-end;
  gap: 0 10px;
}

.timerTxt {
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #a4a4a4;
}

/* 결과 페이지 팝업 END */

/* 결과 페이지  END */

/* 일부 필요 미디어 쿼리 */

@media (max-width: 420px) {
  .testFix span {
    font-size: 12px;
  }
}

@media (max-width: 375px) {
  .titleBox h4 {
    font-size: 15px;
  }
}
