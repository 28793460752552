.couponWrap {
  margin-top: 56px;
}

.couponContent {
  background: #ffffff;
}

/* 쿠폰 입력 */
.codeBox {
  display: flex;
  align-items: center;
  gap: 0 14px;
  padding: 30px 16px 0;
}

.codeBox .inputCode {
  width: calc(100% - 114px);
  height: 42px;
  padding: 0 14px;
  background: #f5f5f5;
  border: 0.7px solid #e7e7e7;
  border-radius: 9px;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  color: #8a8a8a;
}

.codeBox .inputCode::placeholder {
  font-weight: 400;
  font-size: 14px;
  color: #b8b8b8;
}

.codeBox .submitBtn {
  width: 100px;
  height: 42px;
  line-height: 42px;
  background: #e03835;
  border-radius: 9px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
}

/* 쿠폰 리스트 */
.CouponListCon {
  margin-top: 30px;
  padding: 0 16px 30px;
}

.CouponListCon.endCoupon,
.listContent .couponList.endCoupon {
  margin-top: 0;
}

.endCoupon {
  padding-top: 30px;
}

/* 쿠폰이 없을 떄 */
.empty {
  padding-top: 90px;
}

.empty p {
  font-weight: 400;
  font-size: 14px;
  color: #8a8a8a;
  text-align: center;
}

/* 쿠폰이 있을 때*/
.listContent {
  position: relative;
}

.listContent h4 {
  font-weight: 700;
  font-size: 18px;
  color: #0e0e0e;
}

.listContent .couponList {
  margin-top: 30px;
  gap: 15px 0;
  display: flex;
  flex-direction: column;
}

.listContent .couponList .couponBox {
  width: 100%;
  height: 120px;
  padding: 20px 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.12);
  border-radius: 17px;
}

.listContent .couponList .couponDate {
  font-weight: 400;
  font-size: 12px;
  color: #797979;
}

.listContent .couponList .couponBox strong {
  font-weight: 700;
  font-size: 22px;
  color: #e03835;
}
.listContent .couponList .couponBox span {
  font-weight: 700;
  font-size: 16px;
  color: #e03835;
}
.listContent .couponList .couponBox .couponPrice {
  margin-top: 8px;
  font-weight: 400;
  font-size: 14px;
  color: #797979;
}

/* 쿠폰 하단 푸터 */
.couponFooter {
  padding: 16px;
  background: #fafafa;
}

.couponFooter h4 {
  font-weight: 400;
  font-size: 14px;
  color: #797979;
}

.couponFooter .couponInfo {
  margin-top: 15px;
  position: relative;
}
.couponFooter .couponInfo li {
  margin-left: 18px;
  list-style: disc;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  color: #9696a0;
}

.couponFooter .couponInfo li + li {
  margin-top: 10px;
}

/* 적립금 */
.userPoint {
  padding: 30px 0;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.userPoint > span {
  font-weight: 500;
  font-size: 16px;
  color: #141414;
}

.pointDesc {
  margin-top: 15px;
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.pointDesc strong {
  font-weight: 700;
  font-size: 24px;
  color: #141414;
}

.pointDesc p {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background: #e03835;
  border-radius: 50%;
  font-weight: 700;
  font-size: 12px;
  color: #ffffff;
}

.pointContent {
  padding: 16px;
  background: #ffffff;
}

.pointList .left {
  text-align: left;
}

.pointList .right {
  text-align: right;
}

.pointList .center {
  text-align: center;
}

.pointList tr td {
  padding: 15px 0;
  border-bottom: 1px solid #f3f3f3;
}

.pointList .pointName {
  font-weight: 400;
  font-size: 16px;
  color: #1a1a1a;
}

.pointList .num {
  font-weight: 400;
  font-size: 16px;
  color: #797979;
}

.pointList .num.plus {
  color: #e03835;
}

.pointHistory {
  margin-top: 7px;
  display: flex;
  align-items: center;
}

.pointList .pointHistory .shop {
  padding-left: 7px;
  font-weight: 400;
  font-size: 12px;
  color: #9696a0;
}

.pointList .pointHistory .date {
  position: relative;
  padding-right: 7px;
  font-weight: 400;
  font-size: 12px;
  color: #9696a0;
}

.pointList .pointHistory .date::before {
  content: '';
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 1px;
  height: 9px;
  background: #9696a0;
}

/* 적립금 탭*/
.stickyTab {
  z-index: 3;
  position: sticky;
  top: 58px;
  background: #f0f0f0;
}
