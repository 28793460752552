.reviewCon {
  margin-top: 56px;
}

/* 작성 가능한 리뷰 */
.empty {
  margin-top: 70px;
  text-align: center;
}
.empty p {
  font-weight: 400;
  font-size: 18px;
  color: #9a9a9a;
}

.reviewWrap {
  position: relative;
  padding: 25px 16px;
  background: #ffffff;
}

.reviewList {
  display: flex;
  flex-direction: column;
  gap: 15px 0;
}

.reviewList .reviewItem {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.12);
  border-radius: 17px;
  padding: 18px;
  cursor: pointer;
}

.reviewList .reviewItem .reviewTop {
  display: flex;
  align-items: center;
  gap: 0 7px;
}
.reviewList .reviewItem .reviewTop span {
  display: block;
  padding: 0 16px;
  height: 26px;
  line-height: 26px;
  font-weight: 400;
  font-size: 12px;
  color: #363636;
  border: 0.7px solid #888888;
  border-radius: 15px;
}
.reviewList .reviewItem .reviewTop p {
  font-weight: 400;
  font-size: 12px;
  color: #9a9a9a;
}

.reviewList .reviewItem .reviewContent {
  margin-top: 25px;
  display: flex;
  align-items: flex-start;
  gap: 0 10px;
}

.reviewList .reviewItem .reviewContent .shopImg {
  width: 66px;
  height: 66px;
  background: #d9d9d9;
  border-radius: 5px;
}

.reviewList .reviewItem .reviewContent .shopInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: calc(100% - 76px);
  height: 66px;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/link_ic.svg') top 0 right 0 no-repeat;
}

.reviewList .reviewItem .reviewContent .shopInfo .shopTxt strong {
  font-weight: 500;
  font-size: 18px;
  color: #363636;
}

.reviewList .reviewItem .reviewContent .shopInfo .shopTxt span {
  margin-top: 6px;
  display: block;
  font-weight: 400;
  font-size: 14px;
  color: #454545;
}

.reviewList .reviewItem .reviewContent .shopInfo > p {
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  color: #dc2622;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 내가 쓴 리뷰*/
.viewItem + .viewItem {
  border-top: 1px solid #e3e3e3;
}
.viewItem .inner {
  padding: 20px 0;
}

/* View- 헤더 */
.viewItem .viewTop {
  z-index: 10;
  position: relative;
}

.viewItem .viewTop .user {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.viewItem .viewTop .user .userDesc {
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.viewItem .viewTop .user .profile {
  width: 28px;
  height: 28px;
  background: #ededed;
  border-radius: 50%;
}
.viewItem .viewTop .user span {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}

.viewItem .viewTop .user .optBox {
  position: relative;
}

.viewItem .viewTop .user .optBox .optList {
  position: absolute;
  top: 30px;
  right: 0;
  width: 125px;
  background: #ffffff;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.viewItem .viewTop .user .optBox .optList li {
  position: relative;
  text-align: center;
}

.viewItem .viewTop .user .optBox .optList li:active {
  background: #f4f4f4;
}

.viewItem .viewTop .user .optBox .optList li + li::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 75%;
  height: 1px;
  background: #d3d3d3;
}

.viewItem .viewTop .user .optBox .optList li a {
  cursor: pointer;
  display: block;
  font-size: 12px;
  font-weight: 400;
  line-height: 32px;
  color: #969696;
}

.viewItem .viewTop .desc {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.viewItem .viewTop .desc .score {
  display: flex;
  align-items: center;
  gap: 0 5px;
}
.viewItem .viewTop .desc .score span {
  font-weight: 500;
  font-size: 14px;
  color: #313131;
}

.viewItem .viewTop .desc .date {
  font-weight: 400;
  font-size: 10px;
  color: #969696;
}

/* View- 컨텐츠 */
.viewContent {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px 0;
  overflow: hidden;
}

.viewContent .imgSlide {
  display: flex;
  align-items: center;
  gap: 0 16px;
}

.viewContent .slideItem {
  cursor: pointer;
  width: 142px;
  height: 142px;
}

.viewContent .slideItem img {
  width: 100%;
  height: 100%;
}

.viewContent .slideItem:nth-child(2) {
  z-index: 2;
  position: relative;
}

.viewContent .slideItem:nth-child(2)::before {
  content: '';
  z-index: 3;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9) url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/review/review_plus.svg') 50% 50% no-repeat;
}

.viewContent p {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #474747;
}

/* View - 유틸 메뉴 */
.viewItem .viewUtil {
  margin-top: 15px;
}

.viewItem .viewUtil .utilList {
  display: flex;
  align-items: center;
  gap: 0 16px;
}

.viewItem .viewUtil .utilList .wish,
.viewItem .viewUtil .utilList .comment {
  display: flex;
  align-items: center;
  gap: 0 6px;
  cursor: pointer;
}

.viewItem .viewUtil .utilList .wish span,
.viewItem .viewUtil .utilList .comment span {
  font-weight: 400;
  font-size: 14px;
  color: #696969;
}

.viewItem .viewUtil .utilList .wish img {
  width: 15px;
}

.viewItem .shopInfo {
  cursor: pointer;
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.viewItem .shopInfo .shopImg {
  width: 40px;
  height: 40px;
  background: #d9d9d9;
}

.viewItem .shopInfo .shopTxt {
  width: calc(100% - 40px);
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: 5px 0;
  background: #f4f4f4 url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/link_m_ic.svg') top 50% right 12px no-repeat;
  padding: 0 10px;
  box-sizing: border-box;
}

.viewItem .shopInfo .shopTxt strong {
  font-weight: 400;
  font-size: 12px;
  color: #242424;
}

.viewItem .shopInfo .shopTxt span {
  display: block;
  font-weight: 400;
  font-size: 10px;
  color: #bbbbbb;
}

/* 댓글 페이지 */
.commentItem {
  padding: 0 16px;
}

.commentItem .inner {
  padding: 20px 0;
  border-bottom: 1px solid #e3e3e3;
}

.commentItem .commentTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.commentItem .commentTop .user {
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.commentItem .commentTop .user .profile {
  width: 28px;
  height: 28px;
  background: #ededed;
  border-radius: 50%;
}

.commentItem .commentTop .user span {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.commentItem .commentContent {
  margin-top: 15px;
}

.commentItem .commentContent p,
.commentItem .commentContent textarea {
  font-weight: 400;
  font-size: 14px;
  color: #474747;
  line-height: 20px;
  word-break: break-all;
}

.commentItem .commentContent textarea {
  width: 100%;
  min-height: 75px;
  border-radius: 7px;
  border-color: #b8b8b8;
  resize: none;
  outline: none;
  padding: 10px;
  box-sizing: border-box;
}

.writeComment {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 70px;
  padding: 12px 16px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 5px 2px rgba(74, 74, 74, 0.07);
}

.writeComment.browser {
  width: 460px;
}

.writeComment .writeBox {
  display: flex;
  align-items: center;
  gap: 0 5px;
}

.writeComment .writeBox input {
  width: calc(100% - 105px);
  height: 46px;
  line-height: 46px;
  padding: 0 12px;
  box-sizing: border-box;
  border: 1px solid #e7e7e7;
  border-radius: 9px;
  outline: none;
  font-weight: 400;
  font-size: 14px;
  color: #474747;
}

.writeComment .writeBox input::placeholder {
  font-weight: 400;
  font-size: 14px;
  color: #bbbbbb;
}

.writeComment .writeBox .submitBtn {
  width: 100px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  background: #e03835;
  border-radius: 9px;
}

.writeComment .writeBox .submitBtn:disabled {
  background-color: #b8b8b8;
}

/* 글 작성하기 */
.writeCon {
  padding: 50px 16px 70px;
}

.writeCon .writeScore {
  text-align: center;
}

.writeCon .writeScore h4 {
  font-weight: 500;
  font-size: 16px;
  color: #141414;
}

.writeCon .writeScore .shopScore {
  margin-top: 10px;
}

.writeCon .writeScore .scoreImg {
  position: relative;
}

.writeCon .writeScore .scoreComment {
  margin: 10px auto 0 !important;
  display: block;
  padding: 0 7px;
  width: 55px;
  height: 20px;
  line-height: 20px;
  font-weight: 400;
  font-size: 10px;
  color: #dc2622;
  text-align: center;
  background: rgba(220, 38, 34, 0.08);
  border-radius: 5px;
}

.writeCon .writeShop {
  margin-top: 20px;
}

.writeCon .writeShop input {
  width: 100%;
  height: 42px;
  line-height: 42px;
  padding: 0 14px;
  font-weight: 400;
  font-size: 14px;
  color: #dc2622;
  box-sizing: border-box;
  background: rgba(220, 38, 34, 0.08);
  border: 0.5px solid #dc2622;
  border-radius: 6px;
  outline: none;
  cursor: default;
}

.writeCon .secTitle {
  font-weight: 500;
  font-size: 16px;
  color: #797979;
}

.writeCon .writeImg {
  margin-top: 30px;
}

.writeCon .writeImg .imgList {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 6px 6px;
}

.writeCon .writeImg .imgBox .addImg {
  width: 90px;
  height: 90px;
  background: #efefef;
  border: 1.5px solid #d3d3d3;
  border-radius: 6px;
}

.writeCon .writeImg .imgList .imgItem {
  position: relative;
  min-width: 90px;
  width: 90px;
  height: 90px;
  border-radius: 6px;
  border: 1.5px solid #d3d3d3;
}

.writeCon .writeImg .imgList .imgItem .deleteImg {
  position: absolute;
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #dc2622;
  border-radius: 50%;
  top: -6px;
  right: -3px;
}

.writeCon .writeImg .imgList .imgItem > img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.writeCon .writeReview {
  margin-top: 30px;
}

.writeCon .writeReview textarea {
  margin-top: 12px;
  padding: 12px;
  width: 100%;
  min-height: 170px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #5a5a5a;
  background: #ffffff;
  border: 1.5px solid #ebebeb;
  border-radius: 6px;
  box-sizing: border-box;
  outline: none;
  resize: none;
}

.writeCon .writeReview textarea::placeholder {
  font-weight: 400;
  font-size: 14px;
  color: #b9b9b9;
}

/* 리뷰 코멘트 모달 */
.commentModal {
  z-index: 12;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 12px 0;
  background: #ffffff;
  width: 100%;
}

.commentModal h4 {
  padding: 0 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #242424;
}

.commentModal .commentMenu {
  margin-top: 22px;
}
.commentModal .commentMenu button {
  display: block;
  width: 100%;
  padding: 20px 16px;
  font-weight: 400;
  font-size: 16px;
  color: #242424;
  text-align: left;
  box-sizing: border-box;
}

.commentModal .commentMenu button + button {
  border-top: 1px solid #f0f0f0;
}

/* 코멘트 옵션 버튼 */
.commentBtn {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.commentBtn .modifyBtn {
  width: calc(50% - 5px);
  height: 42px;
  line-height: 42px;
  border-radius: 9px;
  box-sizing: border-box;
  border: 1px solid #e03835;
  background: #e03835;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
}

.commentBtn .cancelBtn {
  width: calc(50% - 5px);
  height: 42px;
  line-height: 42px;
  box-sizing: border-box;
  border-radius: 9px;
  border: 1px solid #e03835;
  background: #ffffff;
  font-weight: 700;
  font-size: 16px;
  color: #e03835;
  text-align: center;
}

/* 리뷰 홈에 글쓰기 버튼*/
.reviewBtn {
  z-index: 10;
  position: sticky;
  margin: 0 16px 0 auto;
  bottom: 90px;
  width: 56px;
  height: 56px;
  background: #dc2622;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 4px 2px #0000001a;
}

.reviewBtn img {
  width: 28px;
}

.reviewBtn span {
  font-weight: 400;
  font-size: 11px;
  color: #ffffff;
}
