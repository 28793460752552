/* 회원가입 */

.joinForm {
  background: #f0f0f0;
}

.joinForm > form {
  padding: 30px 16px 0;
  background: #ffffff;
}

.joinForm.accountForm {
  margin-bottom: 96px;
}

.setForm {
  margin: 56px 0 76px;
}

.inputFlex {
  padding: 20px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 16px;
}

.termsBox {
  position: relative;
}

.termsBox + .termsBox {
  border-top: 1px solid #f0f0f0;
}

.termsBox .termsLink {
  display: block;
  padding: 20px 16px;
  font-weight: 400;
  font-size: 16px;
  color: #242424;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/link_ic.svg') right 18px top 50% no-repeat;
}

.inputFlex + .inputFlex {
  border-top: 1px solid #f0f0f0;
}

.inputFlex .switchTit {
  cursor: pointer;
}

.inputFlex .switchTit strong {
  font-weight: 400;
  font-size: 14px;
  color: #242424;
}

.inputFlex .switchTit p {
  margin-top: 6px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #888888;
}

.joinForm .inputInner + .inputInner {
  margin-top: 20px;
}

.joinForm .inputInner .inputFoot {
  margin-top: 10px;
}

.joinForm .inputInner .inputFoot h4 {
  font-weight: 400;
  font-size: 12px;
  color: #f88f8d;
  line-height: 14px;
}

.joinForm .inputInner .inputTit {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.joinForm .inputInner .inputTit h4 {
  font-weight: 400;
  font-size: 14px;
  color: #1a1a1a;
}

.joinForm .inputInner .inputTit small {
  font-weight: 400;
  font-size: 14px;
  color: #e03835;
}

.joinForm .inputInner .inputTit span {
  font-size: 12px;
  font-weight: 400;
  color: #e03835;
}

.joinForm .inputInner .birthInfo {
  margin-top: 6px;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #f88f8d;
}

.joinForm .inputInner input,
.joinForm .inputInner select {
  padding: 0 12px;
  height: 42px;
  border: 0.7px solid #bbbbbb;
  border-radius: 9px;
  box-sizing: border-box;
}

.joinForm .inputInner .flexSelect {
  cursor: pointer;
  flex: 1;
}

.joinForm .inputInner select {
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/dropdown_ic.svg') top 50% right 18px no-repeat;
}

.joinForm .inputInner input.inputFull,
.joinForm .inputInner select.inputFull {
  width: 100%;
  cursor: pointer;
}

.joinForm .inputInner input.inputButton {
  width: calc(100% - 110px);
}

.joinForm .inputInner input.inputMail,
.joinForm .inputInner select.inputMail {
  width: calc(100% - 175px);
}

.joinForm .inputInner select.inputEmail {
  max-width: 140px;
  width: 100%;
  cursor: pointer;
}

.joinForm .inputInner input.inputBirth,
.joinForm .inputInner select.inputBirth {
  max-width: 166px;
  width: 100%;
}

.joinForm .inputInner input.error,
select.error {
  border: 0.7px solid red;
}

.joinForm .inputInner .joinBirthBack {
  width: 43px;
}

.joinForm .inputInner .inputBox {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.joinForm .inputInner .inputBox.agreeAll {
  padding: 4px 0 7px;
}

.joinForm .inputInner .inputBox .all_agree {
  -webkit-appearance: none;
  display: block;
  width: 18px;
  height: 18px;
  border: none;
  outline: none;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/chkbox_off.svg') 50% 50% no-repeat;
}

.joinForm .inputInner .inputBox .all_agree:checked {
  display: block;
  width: 18px;
  height: 18px;
  border: none;
  outline: none;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/chkbox_on.svg') 50% 50% no-repeat;
}

.joinForm .inputInner .inputBox label {
  font-weight: 700;
  font-size: 14px;
  color: #000000;
}

.joinForm .inputInner .inputBox .emailB {
  flex: 1;
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.joinForm .inputInner .inputBox .emailB input {
  flex: 1;
}

.joinForm .inputInner .inputBox button {
  width: 100px;
  height: 42px;
  line-height: 42px;
  background: #ffffff;
  border: 0.8px solid #e03835;
  border-radius: 9px;
  font-weight: 400;
  font-size: 14px;
  color: #e03835;
  text-align: center;
}

.joinForm .inputInner .inputBox button.activeBtn {
  background-color: #e03835;
  font-weight: 700;
  color: #ffffff;
}

.joinForm .inputInner .inputBox button:active {
  background-color: #e03835;
  font-weight: 700;
  color: #ffffff;
}

.joinForm .inputInner .inputBox .birthback {
  display: flex;
  align-items: center;
  gap: 0 6px;
}

.joinForm .inputInner .inputBox .birthback p {
  width: 11px;
  height: 11px;
  background: #d8d8d8;
  border-radius: 50%;
}

.joinForm .idResult {
  margin: 60px 0;
  text-align: center;
}

.joinForm .idResult p {
  margin-bottom: 13px;
  font-weight: 400;
  font-size: 20px;
  color: #1a1a1a;
}

.joinForm .idResult strong {
  font-weight: 700;
  font-size: 20px;
  color: #e03835;
}

.joinForm .idResult span {
  font-weight: 400;
  font-size: 20px;
  color: #1a1a1a;
}

.joinForm .fullBtn {
  display: block;
  width: 100%;
  height: 46px;
  line-height: 46px;
  border: 0.7px solid #e03835;
  background: #ffffff;
  box-sizing: border-box;
  border-radius: 9px;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #e03835;
}

.joinForm .fullBtn:active {
  background-color: #e03835;
  font-weight: 700;
  color: #ffffff;
}

.joinForm .enterLoc {
  margin-top: 15px;
  display: block;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #797979;
  word-break: keep-all;
}

.submit {
  position: relative;
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  background: #ffffff;
  text-align: center;
}

.submit p {
  margin-bottom: 12px;
  font-weight: 500;
  font-size: 14px;
  color: #bcbcbc;
}

.submit p > strong {
  font-weight: 500;
  font-size: 14px;
  color: #636633;
}

.submit .submitBtn {
  display: block;
  width: 100%;
  height: 46px;
  background: #e03835;
  border-radius: 9px;
  font-weight: 700;
  font-size: 16px;
  line-height: 46px;
  text-align: center;
  color: #ffffff;
}

.submit .submitBtn:disabled {
  background-color: #b8b8b8;
}

/* 설정 토글 버튼 */
.setSwitch {
  position: relative;
  display: block;
  width: 50px;
  height: 30px;
  border-radius: 30px;
  background: #bbbbbb;
  cursor: pointer;
  transition: all 0.2s ease-in;
}

.setSwitch .setBtn {
  position: absolute;
  top: 50%;
  left: 3px;
  transform: translateY(-50%);
  width: 26px;
  height: 26px;
  background: #ffffff;
  border-radius: 50%;
  transition: all 0.2s ease-in;
}

.setInput:checked + .setSwitch {
  background: #e03835;
}

.setInput:checked + .setSwitch .setBtn {
  left: calc(100% - 29px);
}

/* 회원탈퇴 */

.accountTit {
  padding-top: 10px;
  text-align: center;
}

.accountTit .infoIc {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 26px;
  background: #dc2622;
  border-radius: 50%;
  font-weight: 900;
  font-size: 20px;
  color: #ffffff;
}

.accountTit span {
  margin-top: 10px;
  display: block;
  font-weight: 500;
  font-size: 24px;
  line-height: 26px;
  color: #000000;
}

.accountTit span > strong {
  font-weight: 700;
  color: #e03835;
}

.accountTit p {
  margin-top: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  color: #000000;
}

.radioList {
  margin: 42px auto 0;
  width: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px 0;
}

.radioList .radioBox {
  width: 200px;
  display: flex;
  align-items: center;
  gap: 0 15px;
}

.radioList .radioBox.areaBox {
  width: 100%;
}

.radioList .radioBox textarea {
  width: 100%;
  height: 150px;
  padding: 12px;
  box-sizing: border-box;
  border: 1px solid #b8b8b8;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: #000000;
  outline: none;
  border-radius: 12px;
  resize: none;
}

.radioList .radioBox input[type='radio'] {
  padding: 0;
  border: transparent;
  width: 18px;
  height: 18px;
  appearance: none;

  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/term_off.svg') no-repeat;
  background-size: 18px 18px;
}

.radioList .radioBox input[type='radio']:checked {
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/term_on.svg');
}

.radioList .radioBox label {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
}

/* 입점 문의 */
.enterInfo {
  padding: 30px 16px;
  background: #ffffff;
}

.enterInfo strong {
  margin-top: 12px;
  display: block;
  font-weight: 500;
  font-size: 20px;
  color: #1c1c1c;
}

.enterInfo p {
  margin-top: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #1c1c1c;
}

.appSet p {
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  color: #e03835;
}

/* 2023-07-25 추가 */
.findUserT {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 0 16px;
}

.findUserT .radioBox {
  display: flex;
  align-items: center;
  gap: 0 5px;
}

.findUserT .radioBox input[type='radio'] {
  appearance: none;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/term_off.svg') no-repeat;
  background-size: 18px 18px;
  border: transparent;
  width: 18px;
  height: 18px;
}

.findUserT .radioBox input[type='radio']:checked {
  background-image: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/term_on.svg');
}

.findUserT .radioBox label {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  cursor: pointer;
}

/* 약관 */
.agreeBox {
  padding: 7px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0 10px;
}

.agreeBox > div {
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.agreeBox input[type='checkbox'] {
  -webkit-appearance: none;
  display: block;
  width: 18px;
  height: 18px;
  border: none;
  outline: none;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/chkbox_off.svg') 50% 50% no-repeat;
}

.agreeBox input[type='checkbox']:checked {
  display: block;
  width: 18px;
  height: 18px;
  border: none;
  outline: none;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/chkbox_on.svg') 50% 50% no-repeat;
}

.agreeBox label {
  font-weight: 700;
  font-size: 14px;
  color: #000000;
}

.agreeBox label > .required {
  margin-left: 5px;
  display: inline-block;
  font-weight: 700;
  font-size: 14px;
  color: #dc2622;
}

.agreeBox label > .option {
    margin-left: 5px;
    display: inline-block;
    font-weight: 700;
    font-size: 14px;
    color: grey;
}

.agreeBox a {
  font-size: 12px;
  font-weight: 400;
  color: #000000;
  text-decoration: underline;
  text-underline-position: under;
}

/* 반응형 작업 */
@media (max-width: 480px) {
  .radioList {
    margin: 42px 0 0 0;
    width: 100%;
  }

  .radioList .radioBox {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 0 15px;
  }

  .radioList .radioBox.areaBox {
    width: 100%;
  }
}

@media (max-width: 340px) {
  .joinForm .inputInner .inputFoot h4 {
    font-size: 10px;
  }

  .joinForm .inputInner .inputBox .birthback p {
    width: 7px;
    height: 7px;
  }

  .joinForm .inputInner .inputBox.emailBox {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px 0;
  }

  .joinForm .inputInner .inputBox.btnBox {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px 0;
  }

  .joinForm .inputInner .inputBox.btnBox input,
  .joinForm .inputInner .inputBox.btnBox button {
    width: 100%;
  }

  .joinForm .inputInner .inputBox.emailBox .emailB {
    width: 100%;
  }

  .joinForm .inputInner .inputBox.emailBox input,
  .joinForm .inputInner .inputBox.emailBox select {
    width: 100%;
    max-width: 100%;
  }
}
