.bookingTab {
  padding: 30px 0;
}

.tabContent {
  padding: 0 16px 30px;
}

.bookingTab .tabList {
  padding: 0 16px;
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.bookingTab .tabList .tabItem {
  cursor: pointer;
  width: auto;
  display: inline-block;
  padding: 0 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 32px;
  color: #bbbbbb;
  background: #f5f5f5;
  border-radius: 15px;
}

.bookingTab .tabList .tabItem.select {
  color: #ffffff;
  background-color: #dc2622;
}

/* 건수 */
.bookCnt {
  font-weight: 700;
  font-size: 18px;
  color: #0e0e0e;
}

/* 예약 리스트 */
.bookingCon {
  margin-top: 30px;
}

.bookingList {
  display: flex;
  flex-direction: column;
  gap: 15px 0;
}

.bookingItem {
  display: block;
  cursor: pointer;
  padding: 18px;
  background: #ffffff;
  border-radius: 17px;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
}

.adminBookItem {
  background: #ffffff url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/link_ic.svg') top 50% right 18px no-repeat;
}

.bookingItem .bookingStatus {
  display: flex;
  align-items: center;
  gap: 0 7px;
}

.bookingItem .bookingStatus .status {
  padding: 0 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 26px;
  text-align: center;
  border: 0.7px solid #888888;
  border-radius: 15px;
}

.bookingItem .bookingStatus .count {
  font-weight: 400;
  font-size: 12px;
  color: #9a9a9a;
}

.bookingInfo {
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 0 11px;
}

.bookingInfo .bookingShop {
  width: 66px;
  height: 66px;
  background: #d9d9d9;
  border-radius: 5px;
}

.bookingItem .bookingTit {
  width: calc(100% - 77px);
  height: 66px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/link_ic.svg') top 0 right 0 no-repeat;
}

.bookingItem .bookingTit.adminBookTit {
  margin-top: 25px;
  width: 100%;
  height: auto;
  justify-content: flex-start;
  background: none;
  gap: 15px 0;
}

.bookingItem .bookingTit .topTit {
  display: flex;
  flex-direction: column;
  gap: 5px 0;
}
.bookingItem .bookingTit .title {
  display: block;
  width: calc(100% - 30px);
  font-weight: 500;
  font-size: 18px;
  color: #363636;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.bookingItem .bookingTit .cate {
  font-weight: 400;
  font-size: 14px;
  color: #454545;
}
.bookingItem .bookingTit .date {
  display: block;
  width: 100%;
  font-weight: 400;
  font-size: 14px;
  color: #dc2622;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.bookingItem .bookingTit .price {
  display: flex;
  align-items: center;
  gap: 0 7px;
}

.bookingItem .bookingTit .price span {
  display: block;
  height: 26px;
  padding: 0 10px;
  background: #e03835;
  border-radius: 15px;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  color: #ffffff;
  box-sizing: border-box;
}
.bookingItem .bookingTit .price strong {
  font-weight: 700;
  font-size: 14px;
  color: #dc2622;
}

/* 예약 확인 */
.bookChkBox {
  padding: 30px 16px 0 16px;
}

.bookChkBox > .content {
  background: #ffffff;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.12);
  border-radius: 17px;
}

.bookChkBox .user.content {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.bookChkBox .bookInfo.content {
  margin-top: 30px;
  padding: 25px 20px;
  box-sizing: border-box;
}
.bookChkBox .bookDate.content {
  margin-top: 15px;
  padding: 20px;
  box-sizing: border-box;
}

.bookChkBox .user .profile {
  width: 34px;
  height: 34px;
  background: #d9d9d9;
  border-radius: 50%;
}

.bookChkBox .userInfo {
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.bookChkBox .user .userInfo strong {
  font-weight: 500;
  font-size: 20px;
  color: #363636;
}

.bookChkBox .user .userInfo span {
  font-weight: 400;
  font-size: 16px;
  color: #797979;
}

.bookChkBox .bookInfo .bookDepth {
  display: flex;
  align-items: center;
  gap: 0 20px;
}

.bookChkBox .bookInfo .bookDepth + .bookDepth {
  margin-top: 20px;
}

.bookChkBox .bookInfo .bookDepth span {
  display: block;
  min-width: 65px;
  font-weight: 400;
  font-size: 16px;
  color: #888888;
}

.bookChkBox .bookInfo .bookDepth strong {
  font-weight: 500;
  font-size: 16px;
  color: #1a1a1a;
}

.bookChkBox .bookInfo .bookButton {
  margin-top: 25px;
  display: flex;
  align-items: center;
  gap: 0 13px;
}

.bookChkBox .bookInfo .bookButton button {
  width: calc(50% - 6.5px);
  height: 40px;
  line-height: 40px;
  background: #f4f4f4;
  border-radius: 9px;
  font-weight: 500;
  font-size: 14px;
  color: #888888;
  text-align: center;
}

.bookChkBox .bookInfo .bookButton .cancelBtn {
  background: #e7e7e7;
}

.bookChkBox .bookInfo .bookButton button.adminBtn {
  width: 100%;
}

.bookChkBox .bookDate .dateInfo {
  display: flex;
  align-items: center;
  gap: 0 5px;
}

.bookChkBox .bookDate .dateInfo span {
  font-weight: 400;
  font-size: 14px;
  color: #e03835;
}

.bookChkBox .bookDate .dateTime {
  margin-top: 25px;
}

.bookChkBox .bookDate .dateTime h4 {
  font-weight: 500;
  font-size: 16px;
  color: #1a1a1a;
}

.bookChkBox .bookDate .dateTime span {
  margin-top: 10px;
  display: block;
  width: 50%;
  height: 42px;
  box-sizing: border-box;
  background: #ffffff;
  border: 0.7px solid #bbbbbb;
  border-radius: 9px;
  padding: 0 15px;
  font-weight: 400;
  font-size: 14px;
  line-height: 42px;
  color: #1a1a1a;
}

.bookChkBox .bookDate .dateTime span.select {
  background: #ffe0e0;
  border-color: #e03835;
  color: #e03835;
}

/* 어드민 예약 내역 */
.adminBookList {
  padding: 30px 16px;
}

.adminButton {
  z-index: 2;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  gap: 0 5px;
  padding: 6px 16px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 0px 5px 2px rgba(74, 74, 74, 0.07);
}

.adminButton.browser {
  width: 460px;
}

.adminButton .cancelBtn {
  flex: 1;
  height: 46px;
  line-height: 46px;
  background: #e7e7e7;
  border-radius: 9px;
  font-weight: 500;
  font-size: 16px;
  color: #888888;
  text-align: center;
}
.adminButton .confirmBtn {
  flex: 1;
  height: 46px;
  line-height: 46px;
  background: #e03835;
  border-radius: 9px;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  text-align: center;
}

.bookModal {
  z-index: 99;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 31px 1px rgba(0, 0, 0, 0.15);
  border-radius: 9px;
  width: 248px;
}

.bookModal .bmContent {
  padding: 40px 35px 30px;
  background: #ffffff;
  border-radius: 9px 9px 0 0;
}

.bookModal .bmContent h4 {
  font-weight: 700;
  font-size: 20px;
  color: #1c1c1c;
}

.bookModal .bmContent ul {
  margin-top: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0 15px;
}

.bookModal .bmContent ul li {
  flex: 1;
  text-align: center;
}

.bookModal .bmContent ul li .stateIc {
  position: relative;
  margin: 0 auto;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}

.bookModal .bmContent ul li.bmApply i {
  position: relative;
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #ffffff;
  border: 3px solid #e03835;
}

.bookModal .bmContent ul li.bmApply span {
  font-weight: 500;
  font-size: 12px;
  color: #e03835;
}

.bookModal .bmContent ul li.bmChk i {
  display: block;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #e03835 url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/payment/pay_chk.svg') 50% 50% no-repeat;
  background-size: 12px 8px;
}

.bookModal .bmContent ul li.bmChk span {
  font-weight: 500;
  font-size: 12px;
  color: #e03835;
}

.bookModal .bmContent ul li.bmConfirm i {
  display: block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #e7e7e7;
}

.bookModal .bmContent ul li.bmConfirm span {
  font-weight: 500;
  font-size: 12px;
  color: #888888;
}

.bookModal .bmContent p {
  margin-top: 35px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #565656;
}

.bookModal .bmContent p > span {
  color: #1a1a1a;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.bookModal button {
  width: 100%;
  height: 50px;
  line-height: 50px;
  color: #ffffff;
  font-weight: 700;
  font-size: 16px;
  background: #e03835;
  border-radius: 0 0 9px 9px;
}

.bookModal .bmContent ul li.bmChk .stateIc::before {
  content: '';
  position: absolute;
  top: 50%;
  left: -45px;
  transform: translateY(-50%);
  width: 45px;
  height: 2px;
  background: #e03835;
}

.bookModal .bmContent ul li.bmChk .stateIc::after {
  content: '';
  position: absolute;
  top: 50%;
  right: -45px;
  transform: translateY(-50%);
  width: 45px;
  height: 2px;
  background: #e7e7e7;
}

@media (max-width: 380px) {
  .bookChkBox .user .userInfo span {
    font-size: 13px;
  }

  .bookChkBox .bookInfo .bookDepth strong {
    display: block;
    width: 100%;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
