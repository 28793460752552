/*  어드민 모달 */
.modalPop {
  z-index: 99;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
}

.modalPop.browser {
  width: 460px;
}

.modalPop .modalBtn {
  margin-top: 22px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  gap: 0 8px;
}

.modalPop .modalBtn button {
  flex: 1;
  height: 46px;
  line-height: 46px;
  border-radius: 9px;
  font-size: 16px;
}

.modalPop .modalBtn .cancelBtn {
  background: #e7e7e7;
  color: #888888;
  font-weight: 500;
}

.modalPop .modalBtn .submitBtn {
  background: #e03835;
  font-weight: 700;
  color: #ffffff;
}

/* 어드민 홈 */
.slideCon {
  position: relative;
}

.slideBG {
  background: #d8d8d8;
  width: 100%;
}

.slideBG img {
  width: 100%;
  cursor: pointer;
}

.menuBox {
  display: block;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 12px 2px rgba(0, 0, 0, 0.12);
  border-radius: 17px;
  padding: 0 20px;
  box-sizing: border-box;
  cursor: pointer;
}

.menuBox.bookRequest,
.menuBox.bookConfirm {
  width: calc(50% - 6.5px);
  height: 140px;
  padding: 25px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.stateTit {
  display: flex;
  align-items: center;
  gap: 0 5px;
}

.stateCnt {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menuBox h4 {
  font-weight: 500;
  font-size: 18px;
  color: #363636;
}

.menuBox strong {
  font-weight: 700;
  font-size: 24px;
  color: #363636;
}

.totalFan,
.todayBook,
.shopConsult {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.bookState {
  display: flex;
  gap: 0 13px;
}

.shopConsult .csTit {
  display: flex;
  align-items: center;
  gap: 0 5px;
}

/* 어드민 마이페이지 */

.myAdminCon {
  padding: 30px 16px 0;
}

.myAdminCon .adminProfile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0 12px;
  background: #d9d9d9;
  border-radius: 11px;
  padding: 20px 25px;
  box-sizing: border-box;
}

.myAdminCon .adminProfile .profile {
  min-width: 80px;
  height: 80px;
  background: #ffffff;
  border-radius: 50%;
  cursor: pointer;
}

.myAdminCon .adminProfile .shopDesc strong {
  font-weight: 700;
  font-size: 20px;
  color: #353535;
}
.myAdminCon .adminProfile .shopDesc span {
  margin-top: 5px;
  display: block;
  font-weight: 400;
  font-size: 18px;
  color: #353535;
}

.myAdminCon .myAdminMenu {
  margin-top: 15px;
}

.myAdminCon .myAdminMenu ul li a {
  display: flex;
  align-items: center;
  gap: 0 6px;
  width: 100%;
  padding: 20px 0;
  font-weight: 400;
  font-size: 16px;
  color: #242424;
  border-bottom: 1px solid #f0f0f0;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/link_m_ic.svg') top 50% right 0 no-repeat;
}

.myAdminCon .myAdminMenu ul li button {
  width: 100%;
  padding: 20px 0;
  font-weight: 400;
  font-size: 16px;
  color: #242424;
  text-align: left;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/link_m_ic.svg') top 50% right 0 no-repeat;
}

/* 모달 */
.profileModal {
  z-index: 12;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  border-radius: 25px 25px 0 0;
  padding: 50px 0 12px;
}

.profileModal h4 {
  padding: 0 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #242424;
}

.profileModal .profileBox {
  padding: 0 16px;
}

.profileModal .profileBox .profileSwiper {
  margin-top: 30px;
}

.profileModal .profileTest {
  position: relative;
  width: 100%;
  background: #ffffff;
  border-radius: 50%;
  border: 2px solid #ffffff;
  cursor: pointer;
}

.profileModal .profileTest.select {
  border-color: #b9110e;
}

.profileModal .profileTest::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.profileModal .profileTest img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 95%;
  height: 95%;
  border-radius: 50%;
}

.calendarBox {
  height: calc(100vh - 118px);
}

.dateSelectWrap {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dateSelectWrap select {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  text-transform: uppercase;
  color: #1c1c1c;
  width: 139px;
  height: 38px;
  outline: none;
  padding: 0 12px;
  border-radius: 30px;
  border: 1px solid #ffffff;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.dateSelectWrap select {
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/dropdown_ic.svg') right 13px top 50% no-repeat;
}

/* 일일 달력 모달 */
.dayCalc {
  z-index: 10000;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 32px);
  height: 74%;
  background: #ffffff;
  border-radius: 20px;
}

.dayCalc .calcHead {
  padding: 20px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dayCalc .calcHead h4 {
  font-weight: 700;
  font-size: 16px;
  color: #1c1c1c;
}

@media (max-width: 380px) {
  .profileModal .profileList li {
    position: relative;
    width: calc(25% - 7.5px);
    background: #3f3f3f;
    border-radius: 50%;
  }
}

@media (max-width: 340px) {
  .myAdminCon .adminProfile .shopDesc strong {
    font-size: 18px;
  }
  .myAdminCon .adminProfile .shopDesc span {
    font-size: 10px;
  }
}
