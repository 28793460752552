/* 마이페이지 */
.myCon {
  position: relative;
  padding-top: 40px;
  overflow: hidden;
  background: #ffffff;
}

.myCon::before {
  content: '';
  z-index: 2;
  position: absolute;
  top: -275px;
  left: 50%;
  transform: translateX(-50%);
  width: 150%;
  height: 53%;
  border-radius: 50%;
  background: #474747;
  box-shadow: 0px 11px 15px 3px rgba(86, 86, 86, 0.29);
}
.myCon .userMenu {
  z-index: 3;
  position: relative;
}
.myCon .userStatus {
  padding: 0 34px;
}

.myCon .userStatus .statusBox {
  display: flex;
  align-items: flex-end;
  gap: 0 16px;
}

.myCon .userStatus .statusBox .profileImg {
  cursor: pointer;
  position: relative;
}

.myCon .userStatus .statusBox .profileImg .profile {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}

.myCon .userStatus .statusBox .profileImg button {
  position: absolute;
  right: 7px;
  bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background: #9696a0;
  border-radius: 50%;
}

.myCon .userStatus .statusBox .userDesc {
  position: relative;
}

.myCon .userStatus .statusBox .userDesc .point {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  gap: 0 3px;
}

.myCon .userStatus .statusBox .userDesc .point a {
  display: flex;
  align-items: center;
  gap: 0 5px;
}

.myCon .userStatus .statusBox .userDesc .point a > p {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14px;
  height: 14px;
  background: #e03835;
  border-radius: 50%;
  font-weight: 700;
  font-size: 10px;
  color: #ffffff;
}

.myCon .userStatus .statusBox .userDesc .point a > strong {
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
}

.myCon .userStatus .statusBox .userDesc .userTit {
  display: flex;
  align-items: flex-end;
  gap: 0 5px;
}

.myCon .userStatus .statusBox .userDesc .userTit > span {
  font-weight: 400;
  font-size: 18px;
  color: #ffffff;
}
.myCon .userStatus .statusBox .userDesc .userTit > strong {
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
}

.myCon .userInfo {
  margin: 15px auto 0;
  width: 100%;
  max-width: calc(100% - 32px);
  padding: 22px 21px 26px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 22px;
  box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.1);
}

.myCon .userInfo h4 {
  font-weight: 400;
  font-size: 14px;
  color: #393939;
}

.myCon .userInfo .infoList {
  margin-top: 15px;
  padding-top: 25px;
  border-top: 1px solid #dfdfdf;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 0 40px;
}

.myCon .userInfo .infoList a {
  display: flex;
  height: 51px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 9px 0;
}

.myCon .userInfo .infoList a > span {
  font-weight: 400;
  font-size: 12px;
  color: #393939;
}

.myCon .mypageNav {
  z-index: 3;
  position: relative;
  margin-top: 30px;
  border-top: 1px solid #f0f0f0;
  padding: 0 16px;
  box-sizing: border-box;
}

.myCon .mypageNav ul li a {
  display: flex;
  align-items: center;
  gap: 0 5px;
  padding: 20px 0;
  font-weight: 400;
  font-size: 16px;
  color: #242424;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/link_m_ic.svg') top 50% right 0 no-repeat;
}

.myCon .mypageNav ul li + li a {
  border-top: 1px solid #f0f0f0;
}

/* 알림 모달 */
.profileModal {
  z-index: 13;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #ffffff;
  border-radius: 25px 25px 0 0;
  padding: 16px 0 12px;
}

.profileModal h4 {
  padding: 0 16px;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #242424;
}

.profileModal .profileSelect {
  margin-top: 22px;
}
.profileModal .profileSelect button {
  display: block;
  width: 100%;
  padding: 20px 16px;
  font-weight: 400;
  font-size: 16px;
  color: #242424;
  text-align: left;
  box-sizing: border-box;
}

.profileModal .profileSelect button + button {
  border-top: 1px solid #f0f0f0;
}

/* 아이디 생성 팝업 */
.accountModal {
  z-index: 13;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 32px);
  background: #ffffff;
  border-radius: 25px;
  padding: 20px 16px;
}

.accountModal .accountTit h4 {
  font-weight: 700;
  font-size: 20px;
  color: #242424;
}

.accountModal .accountTit p {
  margin-top: 10px;
  font-weight: 400;
  font-size: 12px;
  color: #e03835;
}

.accountModal .accountJoin {
  margin-top: 35px;
}

.accountModal .accountJoin .inputBox {
  display: flex;
  align-items: center;
  gap: 0 10px;
}

.accountModal .accountJoin .inputInner h4 {
  font-weight: 400;
  font-size: 14px;
  color: #1a1a1a;
}

.accountModal .accountJoin .inputBox {
  margin-top: 10px;
}

.accountModal .accountJoin .inputBox input {
  width: calc(100% - 110px);

  padding: 0 12px;
  height: 42px;
  border: 0.7px solid #bbbbbb;
  border-radius: 9px;
  box-sizing: border-box;
}

.accountModal .accountJoin .inputBox .accountChk {
  min-width: 100px;
  height: 42px;
  line-height: 42px;
  background: #ffffff;
  border: 0.8px solid #e03835;
  border-radius: 9px;
  font-weight: 400;
  font-size: 14px;
  color: #e03835;
  text-align: center;
}

.accountModal .accountJoin .inputBox .accountChk.activeBtn {
  background-color: #e03835;
  font-weight: 700;
  color: #ffffff;
}

.accountModal .accountJoin .inputBox .accountChk:active {
  background-color: #e03835;
  font-weight: 700;
  color: #ffffff;
}

.accountModal .accountJoin .accountBtn {
  margin-top: 25px;
  width: 100%;
  height: 45px;
  line-height: 45px;
  text-align: center;
  background: #e03835;
  border-radius: 10px;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
}

.accountModal .accountJoin .accountBtn:disabled {
  background-color: #646464;
}

/* 위시리스트 */
.wishContent {
  display: flex;
  flex-direction: column;
  gap: 12px 0;
  padding: 20px 16px;
}

/* 약관 내용 */
.termsData {
  padding: 20px 16px;
}

.termsData h2,
.termsData h3 {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #242424;
}

.termsData p {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #242424;
}

@media (max-width: 360px) {
  .myCon .userInfo .infoList {
    gap: 0 20px;
  }

  .myCon .userStatus .statusBox .userDesc .userTit > strong {
    font-size: 18px;
  }

  .myCon .userStatus .statusBox .userDesc .userTit > span {
    font-size: 12px;
  }

  .myCon .userStatus .statusBox .userDesc .point > strong {
    font-size: 15px;
  }
}
