/* 달력 폰트 */
.fc-dayGridMonth-view .fc-day a {
  font-weight: 400;
  font-size: 12px;
  color: #797979;
}

.fc-dayGridMonth-view .fc-day-sun a {
  color: #e03835;
}

.fc-dayGridMonth-view .fc-day-sat a {
  color: #2256dc;
}

/* 달력 이벤트 */
.fc-dayGridMonth-view .fc-h-event {
  background: #ffe0e0;
  border-color: #ffe0e0;
  border-radius: 3px;
}

.fc-dayGridMonth-view .fc-h-event .fc-event-main {
  font-weight: 400;
  font-size: 10px;
  color: #242424;
  text-align: center;
  padding: 3px 0;
}

.fc-dayGridMonth-view .fc-direction-ltr .fc-daygrid-event.fc-event-end,
.fc-dayGridMonth-view .fc-direction-rtl .fc-daygrid-event.fc-event-start,
.fc-dayGridMonth-view .fc-direction-ltr .fc-daygrid-event.fc-event-start,
.fc-dayGridMonth-view .fc-direction-rtl .fc-daygrid-event.fc-event-end {
  margin: 0;
}

/* 달력 그리드 */
.fc .fc-toolbar.fc-header-toolbar {
  margin: 0;
}

.fc-dayGridMonth-view .fc-daygrid-body {
  margin-top: 15px;
}

.fc-dayGridMonth-view .fc-daygrid-day-frame {
  padding: 5px;
}

.fc-dayGridMonth-view .fc-scrollgrid-sync-table tr + tr td {
  border-top: 1px solid #f0f0f0;
}

.fc-theme-standard .fc-dayGridMonth-view td,
.fc-theme-standard .fc-dayGridMonth-view th,
.fc-theme-standard .fc-dayGridMonth-view .fc-scrollgrid {
  border: none;
}

.fc-dayGridMonth-view .fc .fc-daygrid-day-events {
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 5px 0;
}

.fc-dayGridMonth-view .fc-scroller {
  overflow: hidden !important;
}

/* 일일 달력 */
.fc-timeGridDay-view {
  width: 95%;
  height: 95%;
  margin: 0 auto;
}

.fc-timeGridDay-view .fc-scroller::-webkit-scrollbar {
  display: none;
}
.fc-timeGridDay-view thead {
  display: none;
}

.fc-timeGridDay-view .fc-timegrid-slot-label {
  padding: 10px 0;
}

.fc-timeGridDay-view .fc-timegrid-axis-cushion,
.fc-timeGridDay-view .fc-timegrid-slot-label-cushion {
  padding: 0;
}

.fc-timeGridDay-view .fc-timegrid-slot-label-frame {
  text-align: center;
}

.fc-timeGridDay-view .fc-timegrid-slot-label-cushion {
  font-weight: 500;
  font-size: 12px;
  color: #797979;
}

.fc-timeGridDay-view .fc-event-main,
.fc-timeGridDay-view .fc-bg-event {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.fc-theme-standard .fc-timeGridDay-view .fc-scrollgrid {
  border: none;
}

.fc-timeGridDay-view .fc-timegrid-event-harness > .fc-timegrid-event {
  position: relative;
  display: inline-block;
  padding: 0 16px;
  border-radius: 9px;
  width: calc(100% - 16px);
  height: 85%;
  margin: 2px 8px;
}

.fc-timeGridDay-view .fc-bg-event {
  padding: 0 16px;
  border-radius: 9px;
  background-color: #737373;
  border-color: #737373;
  color: #ffffff;
  opacity: 1;
  margin: 2px 8px;
}

.fc-theme-standard .fc-timeGridDay-view tr td:first-child {
  border: none;
}

.fc-theme-standard .fc-timeGridDay-view tr td + td {
  border: none;
  border-bottom: 1px solid #d8d8d8;
}

.fc-timeGridDay-view .fc-v-event {
  background: #ffe0e0;
  border-color: #ffe0e0;
}

.fc-timeGridDay-view .fc-v-event .fc-event-main {
  font-weight: 500;
  color: #797979;
}

.fc-timeGridDay-view .fc-timegrid-col-events {
  margin: 0;
}
