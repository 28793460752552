.slideCon {
  position: relative;
}

.slideCon img {
  width: 100%;
  cursor: pointer;
}

.slideBG {
  background: #d8d8d8;
  width: 100%;
}

.slideSubBG {
  background: #d8d8d8;
  width: 100%;
  height: 110px;
}

.shopContent {
  margin-top: 8px;
  background: #ffffff;
}

.shopContent .conSec {
  padding: 40px 20px 0;
}

.shopContent .slideSec {
  padding: 40px 0 0 20px;
}

.shopContent .bannerSec {
  padding: 40px 0 0;
}

.secHead {
  padding-right: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.shopContent .conSec .secHead {
  padding-right: 0;
}

.secHead a {
  display: flex;
  align-items: center;
  gap: 0 4px;
  font-weight: 400;
  font-size: 11px;
  color: #888888;
}

.secHead .secTit {
  font-weight: 700;
  font-size: 20px;
  color: #242424;
}

/* 카테고리 네비게이션 */
.cateNavBox {
  padding: 25px 16px;
  background: #ffffff;
}

.cateNavBox .cateNavList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px 20px;
}

.cateNavItem {
  width: calc(20% - 16px);
}

.cateNavItem .navItemBox {
  display: block;
}

.cateNavItem .navItemBox .itemInner {
  position: relative;
  width: 100%;
}

.cateNavItem .navItemBox .itemInner::after {
  content: '';
  display: block;
  padding-top: 100%;
}

.cateNavItem .navItemBox .itemInner .imgBox {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: #f9f9f9;
  padding: 15% 23%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cateNavItem .navItemBox .itemInner .imgBox img {
  width: 100%;
}

.cateNavItem .navItemBox .navTxt {
  margin-top: 5px;
  display: block;
  font-weight: 400;
  font-size: 11px;
  color: #1c1c1c;
  text-align: center;
}

/* 최근 본 샵 */
.shopContent .recentSec {
  padding: 40px 0 40px 16px;
  background: #ffffff;
  border-bottom: 1px solid #f0f0f0;
}

/* 공지사항 */
.shopContent .noticeSec {
  padding: 40px 16px;
}

.shopContent .noticeSec .slideNotice {
  display: flex;
  align-items: center;
  gap: 0 5px;
}

.shopContent .noticeSec .slideNotice h4 {
  font-weight: 500;
  font-size: 12px;
  color: #242424;
}

.shopContent .noticeSec .slideNotice .noticeName {
  font-weight: 400;
  font-size: 12px;
  color: #888888;
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
