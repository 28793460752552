/* 로그인 */
.loginForm {
  position: relative;
  padding-top: 90px;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.loginForm .loginLogo {
  z-index: 3;
  position: relative;
  text-align: center;
}

.loginForm form {
  margin-top: 80px;
  z-index: 3;
  position: relative;
}

.loginForm .loginBox {
  margin: 0 auto;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 14px 0;
}

.loginForm .loginBox .inputInner {
  position: relative;
  display: flex;
  align-items: center;
  gap: 0 11px;
  width: 100%;
  box-sizing: border-box;
}

.loginForm .loginBox .inputInner input {
  width: 100%;
  height: 45px;
  line-height: 45px;
  padding: 20px 16px;
  font-weight: 400;
  font-size: 14px;
  color: #797979;
  border: 1px solid #bbbbbb;
  border-radius: 21px;
  outline: none;
  box-sizing: border-box;
}

.loginForm .loginBox .inputInner input::placeholder {
  font-weight: 400;
  font-size: 14px;
  color: #cfcfcf;
}

.loginForm .loginBtn {
  margin-top: 20px;
  width: 100%;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px 0;
}

.loginForm .loginBtn .login {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background: #e03835;
  border-radius: 21px;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  text-align: center;
}

.loginForm .loginState {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0 5px;
}

.loginForm .loginState input[type='checkbox'] {
  padding: 0;
  border: transparent;
  width: 18px;
  height: 18px;
  appearance: none;
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/circle_chk_off.svg') no-repeat;
}

.loginForm .loginState input[type='checkbox']:checked {
  background: url('https://mendle-s3-bucket.s3.ap-northeast-2.amazonaws.com/beauty-book/bb/common/circle_chk_on.svg') no-repeat;
}

.loginForm .loginState label {
  font-weight: 400;
  font-size: 12px;
  color: #888888;
}

.loginForm .snsLogin {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 22px 0;
}

.loginForm .snsLogin .snsTit {
  position: relative;
  font-weight: 400;
  font-size: 12px;
  color: #888888;
}

.loginForm .snsLogin .snsTit::before {
  content: '';
  position: absolute;
  left: -90px;
  top: 50%;
  transform: translateY(-50%);
  width: 80px;
  height: 0.5px;
  background: #888888;
}

.loginForm .snsLogin .snsTit::after {
  content: '';
  position: absolute;
  right: -90px;
  top: 50%;
  transform: translateY(-50%);
  width: 80px;
  height: 0.5px;
  background: #888888;
}

.loginForm .snsLogin .snsList {
  display: flex;
  align-items: center;
  gap: 0 25px;
}

.loginForm .snsLogin a {
  font-weight: 500;
  font-size: 14px;
  color: #acacac;
}

.loginForm .snsLogin div {
  display: none;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex a {
  position: relative;
  display: block;
  padding: 0 15px;
  font-weight: 500;
  font-size: 12px;
  color: #797979;
}

.flex a + a::before {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 1px;
  height: 12px;
  background: #797979;
}

.flex a:first-child {
  padding-left: 0;
}
.flex a:last-child {
  padding-right: 0;
}
